const initialState = {
  version: "0.197",
  versionnumber: 18,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {

    default:
      return state;
  }
};

export default reducer;
