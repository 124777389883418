import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import {
  loadProject,
  saveProject,
  publishProject,
  rePublishProject,
} from "Redux/project/actions";
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait/";
// -----------------------------------------------------
const rubrics = [
  { title: "Local", rubric: "local" },
  { title: "World", rubric: "world" },
  { title: "Sports", rubric: "sports" },
  { title: "Economy", rubric: "economy" },
  { title: "Health", rubric: "health" },
  { title: "Science", rubric: "science" },
  { title: "Culture", rubric: "culture" },
];


// -----------------------------------------------------
// -----------------------------------------------------
const Publish = () => {
  const dispatch = useDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [generating, setGenerating] = useState(false);

  const project = useSelector((state) => state.project);

  const { pid } = useParams();
  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  const keys = Object.keys(project.progress);
  let readyToPublish = true;
  keys.forEach((key) => {
    if (!project.progress[key]) {
      readyToPublish = false;
    }
  });

  if (!hasLoaded) {
    return <PleaseWait />;
  }

  const doPublish = project.published ? rePublishProject : publishProject;

  return (
    <Frame>
      <Back>
        <button
          className={`button ${generating ? "is-loading" : ""}`}
          onClick={() => {
            setGenerating(true);
            dispatch(
              doPublish(project, () => {
                setGenerating(false);
              })
            );
          }}
          disabled={!readyToPublish}
        >
          <span className="icon">
            <i className="fa-duotone fa-paper-plane"></i>
          </span>
          <span>{project.published ? "Re-publish" : "Publish"}</span>
        </button>
      </Back>
      <div className="box">
        <table className="table">
          <tbody>
            <tr>
              <th>Rubric</th>
              <td>
                <div className="select">
                  <select
                    value={project.category}
                    onChange={(evt) => {
                      dispatch({
                        type: "SET_PROJECT_CATEGORY",
                        category: evt.target.value,
                      });
                    }}
                  >
                    {rubrics.map((rubric, index) => {
                      return (
                        <option key={rubric.rubric} value={rubric.rubric}>
                          {rubric.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th>Publication Status</th>
              <td>
                {project.published ? (
                  <div className="tag is-success">Published</div>
                ) : (
                  <div className="tag">Unpublished</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Frame>
  );
};

export default Publish;
