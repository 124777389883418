import { useDebugValue, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------
import { languages } from "Data/languages";

// ------------------------------------------------
// ------------------------------------------------
const Edit = ({ onClose, editing }) => {
  const dispatch = useDispatch();

  const { teachingLanguage } = useSelector((state) => state.user);
  const allLanguages = languages[teachingLanguage].map((x) => x.langcode);

  const project = useSelector((state) => state.project);
  const tokens = JSON.parse(JSON.stringify(project.tokens)) || [];
  const sentences = JSON.parse(JSON.stringify(project.sentences)) || [];

  const thisToken = editing.token;
  const thisLocation = editing.location;
  const thisSentence = editing.sentence;

  console.log(thisLocation);

  const [hasChanged, setHasChanged] = useState(false);
  const [translations, setTranslations] = useState(
    JSON.parse(JSON.stringify(thisToken.translations))
  );


  const updateTranslation = (language, translation) => {
    setTranslations({
      ...translations,
      [language]: translation,
    });
  }

  const saveChanges = () => {
    const { parindex, sentindex, tokenindex } = thisLocation;
    tokens[parindex][sentindex][tokenindex] = {
      ...tokens[parindex][sentindex][tokenindex],
      translations,
      uri: null,
    };
    dispatch({ type: "UPDATE_TOKENS", tokens });
    onClose();
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <b>{thisToken.lemma}</b> |{" "}
            <span className="small">{thisToken.pos}</span>
          </p>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>

        <section className="modal-card-body">
          <div className="box">{thisSentence.text}</div>
          <div className="box">
            <TranslationItem
                  translations = {translations}
                  language="en"
              setHasChanged={setHasChanged}
              updateTranslation={updateTranslation}
            />
            <hr />
            <div className="field is-grouped is-grouped-multiline">
              {allLanguages.map((lang) => (
                <TranslationItem
                  translations = {translations}
                  language={lang}
                  setHasChanged={setHasChanged}
                  updateTranslation={updateTranslation}
                />
              ))}
            </div>
          </div>
        </section>

        <footer className="modal-card-foot">
          <div className="buttons">
            {hasChanged ? (
              <button className="button is-success" onClick={saveChanges}>
                Save Changes
              </button>
            ) : (
              <button className="button is-success" disabled>
                Save Changes
              </button>
            )}
            <button className="button" onClick={onClose}>
              Close
            </button>
            <button className = "button">Translate EN</button>
            <button className = "button">Translate All</button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Edit;

const TranslationItem = ({ translations, language, setHasChanged, updateTranslation }) => {
  const [editing, setEditing] = useState(false);
  const [translation, setTranslation] = useState("");

  useEffect(() => {
    setTranslation(
      translations && translations[language]
        ? translations[language]
        : ""
    );
  }, [translations, language]);

  const submit = () => {
    setEditing(false);
    updateTranslation(language, translation);
    setHasChanged(true);
  };

  return (
    <div class="control is-clickable" onClick={() => setEditing(!editing)}>
      <div className="tags are-large has-addons">
        <span className="tag is-warning is-light">{language}</span>
        {editing ? (
          <span className="tag">
            <input
              className="input is-small"
              autoFocus
              type="text"
              value={translation}
              onChange={(evt) => {
                setTranslation(evt.target.value);
              }}
              onBlur={submit}
              onKeyDown={(evt) => {
                evt.key === "Enter" && submit();            
              }}
            />
          </span>
        ) : (
          <span className="tag">{translation}</span>
        )}
      </div>
    </div>
  );
};
