import { backendNode } from "System/system";
import store from "System/reduxStore";
import { loadProject } from "Redux/project/actions";
import { illustrateText } from "Redux/illustration/actions";
import { findVocab, generateQuestions } from "Redux/quiz/actions";

const nullProgress = {
  text: false,
  tokens: false,
  speech: false,
  translations: false,
  textpic: false,
  illustrations: false,
  quiz: false,
};

// -------------------------------------------------------
// -------------------------------------------------------
const setProject = (project, projectid) => (dispatch) => {
  delete project._id;
  project.id = projectid;
  dispatch({ type: "SET_PROJECT", project });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const generateTextAnalysis = (cb) => async (dispatch) => {
  const user = store.getState().user;
  const project = store.getState().project;
  const projectid = project.id;
  const { paragraphs } = project;
  const { teachingLanguage } = user;

  const text = paragraphs.join("\n\n").trim();

  const res = await backendNode("process", "generateTextAnalysis", {
    text,
    projectid,
    language: teachingLanguage,
  });

  if (res.data) {
    dispatch(
      setProject(
        {
          ...res.data.project,
          progress: {
            ...nullProgress,
            text: true,
            tokens: true,
          },
        },
        projectid
      )
    );
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const generateTranslations = (cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;

  const res = await backendNode("process", "generateTranslations", {
    projectid,
  });
  if (res.data) {
    dispatch(
      setProject(
        {
          ...res.data.project,
          progress: {
            ...nullProgress,
            text: true,
            tokens: true,
            speech: true,
            translations: true,
          },
        },
        projectid
      )
    );
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const generateQuiz = (cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;
  const { paragraphs, tokens } = project;

  const text = paragraphs.join("\n\n").trim();
  await dispatch(findVocab(tokens, text, () => {}));
  await dispatch(generateQuestions(text, () => {}));
  dispatch(
    setProject(
      {
        ...project,
        progress: {
          ...project.progress,
          quiz: true,
        },
      },
      projectid
    )
  );
  cb();
};
// -------------------------------------------------------
// -------------------------------------------------------
export const moveCloudinaryArticeImageFile = (sourcePublicId, targetPublicId, cb) => async (dispatch) => {
    const project = store.getState().project;
    const projectid = project.id;
    const res = await backendNode("cloudinary", "moveFile", {
        sourcePublicId,
        targetPublicId,
    });
    if (res.data) {
        console.log("copied over the selected image: ", res.data.secure_url);
        dispatch(
          setProject(
            {
              ...project,
              textpic: res.data.secure_url,
              progress: {
                ...project.progress,
                textpic: true,
              },
            },
            projectid
          )
        );
    }
    cb();
}

export const moveCloudinaryTokenImage = (parindex, sentindex, tokenindex, sourcePublicId, targetPublicId, cb) => async (dispatch) => {
    const project = store.getState().project;
    const projectid = project.id;
    const res = await backendNode("cloudinary", "moveFile", {
        sourcePublicId,
        targetPublicId,
    });
    if (res.data) {
        console.log("copied over the selected image: ", res.data.secure_url);
        // Create a copy of the tokens and update the specific token's URI
      const updatedTokens = [...project.tokens];
      updatedTokens[parindex] = [...updatedTokens[parindex]];
      updatedTokens[parindex][sentindex] = [...updatedTokens[parindex][sentindex]];
      updatedTokens[parindex][sentindex][tokenindex] = {
        ...updatedTokens[parindex][sentindex][tokenindex],
        uri: res.data.secure_url,
      };
        dispatch(
          setProject(
            {
              ...project,
              tokens: updatedTokens,
            },
            projectid
          )
        );
    }
    cb();
}

// -------------------------------------------------------
// -------------------------------------------------------
export const textIllustration = (cb) => async (dispatch) => {
  const project = JSON.parse(JSON.stringify(store.getState().project));
  const projectid = project.id;
  const text = project.paragraphs.join("\n\n").trim();

  console.log("Illustrating the text");
  dispatch(
    illustrateText(text, projectid, ({uri}) => {
      console.log("Illustration done", uri);
      if (uri) {
        dispatch(
          setProject(
            {
              ...project,
              textpic: uri,
              progress: {
                ...project.progress,
                textpic: true,
              },
            },
            projectid
          )
        );
      }
      cb();
      return;
    })
  );
};

// -------------------------------------------------------
// -------------------------------------------------------
export const illustrateTokens = (cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;

  const res = await backendNode("process", "generateTokenIllustrations", {
    projectid,
  });
  if (res.data) {
    dispatch(
      setProject(
        {
          ...res.data.project,
          progress: {
            ...project.progress,
            illustrations: true,
          },
        },
        projectid
      )
    );
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const generateSpeech = (cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;
  const res = await backendNode("process", "generateSpeech", {
    projectid,
  });
  if (res.data) {
    dispatch(setProject({
      ...res.data.project,
      progress: {
        ...project.progress,
        speech: true,
      },
    }, projectid));
  }
  cb();
};

export const generateTokenSpeech = (parindex, sentindex, tokenindex, cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;
  const res = await backendNode("process", "generateTokenSpeech", {
    projectid,
    parindex,
    sentindex,
    tokenindex,
  });
  if (res.data) {
    dispatch(setProject({
      ...res.data.project,
      progress: {
        ...project.progress,
        speech: true,
      },
    }, projectid));
  }
  cb();
};

export const generateSentenceSpeech = (parindex, sentindex, cb) => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;
  const res = await backendNode("process", "generateSentenceSpeech", {
    projectid,
    parindex,
    sentindex,
  });
  if (res.data) {
    dispatch(setProject({
      ...res.data.project,
      progress: {
        ...project.progress,
        speech: true,
      },
    }, projectid));
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const refreshStatus = () => async (dispatch) => {
  const project = store.getState().project;
  const projectid = project.id;
  const previous = project.projectstatus;
  const result = await backendNode("process", "getStatus", { projectid });
  if (result.data) {
    const projectstatus = result.data.projectstatus;
    if (projectstatus !== previous) {
      if (projectstatus === "analysis-done") {
        dispatch(loadProject(projectid, () => {}));
        return;
      }
      dispatch({ type: "SET_PROCESS_STATUS", projectstatus });
    }
  }
};
