import md5 from "md5";
// -------------------------------------------------------
import { backendPy, backendNode } from "System/system";
import store from "System/reduxStore";


// -------------------------------------------------------
// -------------------------------------------------------
export const illustrateText = (text, projectid, cb) => async (dispatch) => {
  console.log("Inside illustrateText");
  const prompt = `
  You are an illustrator of texts. Provide a short description of a painting to illustrate the following text. 
  Use less than 30 words. The text: "${text}"`;

  let tik = Date.now();
  let res = await backendNode("classroom", "query", { prompt });

  if (res.data) {
    const description = res.data.reply;
    tik = Date.now();
    res = await backendNode("illustrate", "dalle", {
      description,
      pictureid: `main/de/${projectid}`,
    });

    if (res.data) {
      const uri = res.data.uri;
      
      var img = new Image();
      img.src = uri;
      img.onload = () => {
        cb({uri});
        return;
      };
      return;
    }
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
const generatePictureId = (lemma, english, pos) => md5(`${lemma}-${english}-${pos}`)

// -------------------------------------------------------
// -------------------------------------------------------
export const illustrateToken = (token, location, cb) => async (dispatch) => {

  const res = await backendPy("illustrate", "describe", {
    input: JSON.stringify([{ de: token.lemma, en: token.translations.en }]),
  });
  
  if (res.data) {
    console.log(res.data.result);
    const description = eval(res.data.result)[0].illustration;
    await dispatch(rePaint(token, description, location, uri => {
      cb(description, uri);
    }));
    return;
  }
  cb("", "");
};

// -------------------------------------------------------
// -------------------------------------------------------
export const rePaint = (token, description, location, cb) => async (dispatch) => {
  const { teachingLanguage } = store.getState().user;
  token = JSON.parse(JSON.stringify(token));

  const res = await backendNode("illustrate", "illustrateWord", {
    sourcelang: teachingLanguage,
    isglobal: true,
    description,
    de: token.lemma,
    en: token.translations.en,
    pos: token.pos,
  });
  let uri = "";
  if (res.data) {
    uri = res.data.uri;
    token.uri = uri;
    token.description = description;
    dispatch({ type: "SET_TOKEN", token, location });
    const img = new Image();
    img.src = uri;
  }
  cb(uri);
};
