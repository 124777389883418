import { initializeApp } from "firebase/app";

export const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyDH4ZoUCUq3Wmq3AcIVacQG5Pt4xUREyg4",
    authDomain: "lingoberryclassroom.firebaseapp.com",
    projectId: "lingoberryclassroom",
    storageBucket: "lingoberryclassroom.appspot.com",
    messagingSenderId: "880923342677",
    appId: "1:880923342677:web:11636dc65708f87f5961dc",
  };
  initializeApp(firebaseConfig);
};
