import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import { cloudinaryResize } from "Auxiliary/";
import { loadProject, saveProject } from "Redux/project/actions";
import {moveCloudinaryArticeImageFile, textIllustration} from "Redux/process/actions";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait/";
import CloudinaryUploadWidget from "../../Components/CloudinaryUploadWidget";
// -----------------------------------------------------
const resize = (uri) => {
  const timestamp = new Date().getTime(); // Get the current timestamp
  return `${cloudinaryResize(uri, 300, 300)}?t=${timestamp}`;
};

// -----------------------------------------------------
// -----------------------------------------------------
const TextIllustration = () => {
  const dispatch = useDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [redoing, setRedoing] = useState(false);
  const project = useSelector((state) => state.project);
  const { pid } = useParams();
  const [cloudinaryPublicId, setCloudinaryPublicId] = useState("");

  const [uwConfig] = useState({
    cloudName: "dfumqwz6y",
    uploadPreset: "ygrol6ql",
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    // sources: [ "local", "url"], // restrict the upload sources to URL and local files
    // multiple: false,  //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    // clientAllowedFormats: ["images"], //restrict uploading to image files only
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });
  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  useEffect(() => {
      if (cloudinaryPublicId) {
          dispatch(
                  moveCloudinaryArticeImageFile(cloudinaryPublicId, `main/de/${pid}`, () => {})
              );
      }
  }, [cloudinaryPublicId]);

  if (!hasLoaded) {
    return <PleaseWait />;
  }


  const text = project.paragraphs.join("\n\n").trim();


  return (
    <Frame>
      <Back>
        <button
            className={`button ${redoing ? "is-loading" : ""}`}
            onClick={() => {
              setRedoing(true);
              dispatch(
                  textIllustration(() => {
                    setRedoing(false);
                  })
              );
            }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Illustrate Text</span>
        </button>
          <CloudinaryUploadWidget uwConfig={uwConfig} setPublicId={setCloudinaryPublicId} />
      </Back>

      {project.progress.textpic && (
          <div className="box">
            <h5 className="title is-5">{project.title}</h5>
          <hr />
          <div className="columns">
            <div className="column">
              <div className="textpic-container">
                <img src={resize(project.textpic)} />
                <div
                  className="textpic-redo"
                  onClick={() => {
                    setRedoing(true);
                    dispatch(
                      textIllustration(() => {
                        setRedoing(false);
                        console.log("illustrated");
                      })
                    );
                  }}
                >
                  <i
                    className={`fa-duotone fa-redo-alt ${redoing && "fa-spin"}`}
                  ></i>
                </div>
              </div>
            </div>
            <div className="column">{text}</div>
          </div>
        </div>
      )}
    </Frame>
  );
};

export default TextIllustration;
