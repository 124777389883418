import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import {
  loadProject,
  saveProject,
  mergeTokens,
  splitTokens,
} from "Redux/project/actions";
import { generateTextAnalysis } from "Redux/process/actions";
import Edit from "./EditPopup";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait/";
// -----------------------------------------------------

// -----------------------------------------------------
// -----------------------------------------------------
const Analysis = () => {
  const dispatch = useDispatch();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [generating, setGenerating] = useState(false);

  const [merging, setMerging] = useState(false);
  const [editing, setEditing] = useState(true);
  const [splitting, setSplitting] = useState(false);
  const [editingPopup, setEditingPopup] = useState(false);
  const [tokenChain, setTokenChain] = useState({});
  const [mergedLocations, setMergedLocations] = useState([]);

  const project = useSelector((state) => state.project);

  const { pid } = useParams();
  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
    const chain = {};
    let cnt = 0;
    if (project.tokens) {
      project.tokens.forEach((par, parindex) => {
        par.forEach((sent, sentindex) => {
          sent.forEach((token, tokenindex) => {
            chain[`${parindex}-${sentindex}-${tokenindex}`] = cnt++;
          });
        });
      });
      setTokenChain(chain);
    }
  }, [project]);

  const hasParagraphs =
    project.hasOwnProperty("paragraphs") && project.paragraphs.length > 0;

  const tokens = JSON.parse(JSON.stringify(project.tokens || []));
  const sentences = JSON.parse(JSON.stringify(project.sentences || []));

  const { teachingLanguage } = useSelector((state) => state.user);
  const direction = teachingLanguage === "he" ? "is-rtl" : "is-ltr";

  if (!hasLoaded) {
    return <PleaseWait />;
  }

  if (!hasParagraphs) {
    return (
      <Frame>
        <Back />
        <div className="box">
          <div className="notification is-warning is-light">
            No paragraphs found. Make sure to define a text first.
          </div>
        </div>
      </Frame>
    );
  }

  return (
    <Frame>
      <Back>
        <button
          className={`button ${generating ? "is-loading" : ""}`}
          onClick={() => {
            setGenerating(true);
            dispatch(
              generateTextAnalysis(() => {
                setGenerating(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Generate Analysis</span>
        </button>
      </Back>
      {editingPopup ? (
        <Edit onClose={() => setEditingPopup(null)} editing={editingPopup} />
      ) : null}
      <div
        className="box"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="buttons are-small">
          <Buttons
            mergedLocations={mergedLocations}
            setMergedLocations={setMergedLocations}
            splitting={splitting}
            setSplitting={setSplitting}
            merging={merging}
            setMerging={setMerging}
            editing={editing}
            setEditing={setEditing}
          />
        </div>
      </div>
      <div className={`box ${direction}`}>
        {tokens.map((tokenpar, parindex) => (
          <div key={`parindex-${parindex}`}>
            <div>
              {tokenpar.map((tokensent, sentindex) => (
                <div key={`sentindex-${parindex}-${sentindex}`}>
                  <div className="item-container">
                    {tokensent.map((token, tokenindex) => {
                      const location = `${parindex}-${sentindex}-${tokenindex}`;
                      return (
                        <Word
                          key={`token-${parindex}-${sentindex}-${tokenindex}`}
                          token={token}
                          merging={merging}
                          isPicked={mergedLocations.includes(location)}
                          onClick={(token) => {
                            if (merging) {
                              if (mergedLocations.includes(location)) {
                                setMergedLocations(
                                  mergedLocations.filter((x) => x !== location)
                                );
                                return;
                              }
                              let isAllowed = false;

                              // allow pick if it's the first location
                              if (mergedLocations.length === 0) {
                                isAllowed = true;
                              }
                              // also allow pick if it's next to an existing location
                              const mergedIndex = tokenChain[location];
                              const mergedDistances = mergedLocations.map(
                                (x) => tokenChain[x] - mergedIndex
                              );
                              if (
                                mergedDistances.includes(1) ||
                                mergedDistances.includes(-1)
                              ) {
                                isAllowed = true;
                              }

                              if (isAllowed) {
                                setMergedLocations([
                                  ...mergedLocations,
                                  location,
                                ]);
                              }
                              return;
                            }
                            if (splitting) {
                              dispatch(splitTokens(location));
                            }
                            if (editing) {
                              const sentence = sentences[parindex][sentindex];
                              console.log("Editing click", location);
                              setEditingPopup({
                                token,
                                sentence,
                                location: { parindex, sentindex, tokenindex },
                              });
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Frame>
  );
};

export default Analysis;

// -------------------------------------------------------------
// ------------- Buttons  --------------------------------------
const Buttons = ({
  mergedLocations,
  setMergedLocations,
  splitting,
  setSplitting,
  editing,
  setEditing,
  merging,
  setMerging,
}) => {
  const dispatch = useDispatch();
  const canMerge = mergedLocations.length >= 2;

  if (merging) {
    return (
      <>
        <button
          className={`button is-warning`}
          onClick={() => {
            dispatch(
              mergeTokens(mergedLocations, () => {
                setMergedLocations([]);
                setMerging(false);
              })
            );
          }}
          disabled={!canMerge}
        >
          <span className="icon">
            <i className="fa-duotone fa-solid fa-merge fa-beat" />
          </span>
          <span>Merge Tokens</span>
        </button>

        <button className={`button`} onClick={() => setMerging(false)}>
          <span className="icon">
            <i className="fa-duotone fa-times" />
          </span>
          <span>Discard</span>
        </button>
      </>
    );
  }

  if (splitting) {
    return (
      <button
        className={`button is-warning`}
        onClick={() => {
          setSplitting(false);
        }}
      >
        <span className="icon">
          <i className="fa-duotone fa-solid fa-split fa-beat" />
        </span>
        <span>Splitting Tokens</span>
      </button>
    );
  }

  if (editing) {
    return (
      <button
        className={`button is-warning`}
        onClick={() => {
          setEditing(false);
        }}
      >
        <span className="icon">
          <i className="fa-duotone fa-solid fa-edit fa-beat" />
        </span>
        <span>Editing Tokens</span>
      </button>
    );
  }

  return (
    <>
      <button
        className={`button`}
        onClick={() => {
          setEditing(true);
        }}
      >
        <span className="icon">
          <i className="fa-duotone fa-solid fa-edit" />
        </span>
        <span>Edit Tokens</span>
      </button>

      <button
        className={`button`}
        onClick={() => {
          setMerging(true);
          setMergedLocations([]);
        }}
      >
        <span className="icon">
          <i className="fa-duotone fa-solid fa-merge" />
        </span>
        <span>Merge Tokens</span>
      </button>
      <button
        className={`button`}
        onClick={() => {
          setSplitting(true);
        }}
      >
        <span className="icon">
          <i className="fa-duotone fa-solid fa-split" />
        </span>
        <span>Split Tokens</span>
      </button>
    </>
  );
};

// -------------------------------------------------------------
// ------------- Word  -----------------------------------------
const Word = ({ token, onClick, isPicked, merging }) => {
  const thisClass = merging
    ? `item pickable ${isPicked ? "picked" : ""}`
    : "item";
  return (
    <div
      className={thisClass}
      onClick={() => {
        onClick(token);
      }}
    >
      <div className="item-pos">
        {token.pos !== "punctuation" ? token.pos : "\u00A0"}
      </div>
      <div className="item-main">{token.token}</div>
      <div className="item-root">
        {token.pos !== "punctuation" ? token.lemma : "\u00A0"}
      </div>
    </div>
  );
};
