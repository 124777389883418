import { nullProgress } from "./nullProgress";

const zero = {
  paragraphs: [],
  tokens: [],
  sentences: [],
  title: "",
  textpic: "",
  maxCnt: 0,
  projectstatus: null,
  stage: 0,
};

export const initialState = {
  ...zero,
  data: {
    textlength: 2,
    difficulty: 0,
    topic: "",
    keywords: "",
    vocabulary: "",
  },
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let tokens, sentences, loc;

  // -------------------------------------------------------
  switch (action.type) {
    // --------------------------------------------------------------
    case "SET_PROJECT_TEXTLENGTH":
      return {
        ...state,
        textlength: action.textlength,
      };

    // --------------------------------------------------------------
    case "SET_PROJECT_CATEGORY":
      return {
        ...state,
        category: action.category,
      };

    // --------------------------------------------------------------
    case "SET_PROJECT_DIFFICULTY":
      return {
        ...state,
        difficulty: action.difficulty,
      };

    // --------------------------------------------------------------
    case "CLEAR_PROJECT_STATUS":
      return {
        ...state,
        projectstatus: null,
      };
    // --------------------------------------------------------------
    case "SET_PROCESSING_STAGE":
      if (action.stage === 2) {
        return {
          ...state,
          stage: action.stage,
          projectstatus: null,
        };
      }
      return {
        ...state,
        stage: action.stage,
      };
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_PROGRESS":
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.leaf]: 3,
        },
      };
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "RESET_PROGRESS":
      return {
        ...state,
        progress: {
          text: 3,
        },
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_VOCAB":
      return {
        ...state,
        vocab: action.vocab,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "PUBLISH_PROJECT":
      return {
        ...state,
        published: true,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_ANALYZING":
      return {
        ...state,
        isAnalyzing: action.isAnalyzing,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_SINGLE_SET_OF_FOILS":
      console.log(action);
      return {
        ...state,
        vocab: state.vocab.map((v) => {
          const newVocab = JSON.parse(JSON.stringify(v));
          if (v.token === action.token.token) {
            newVocab.foils = action.foils;
          }
          return newVocab;
        }),
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "ADD_VOCAB":
      let vocab = state.vocab || [];
      if (state.vocab) {
        const lemmas = state.vocab.map((v) => v.lemma);
        if (lemmas.includes(action.token.lemma)) return state;
      }
      return {
        ...state,
        vocab: [action.token, ...vocab],
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "REMOVE_VOCAB":
      return {
        ...state,
        vocab: [...state.vocab].filter((v, i) => i !== action.tokenindex),
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_BOTH_DIRECTIONS":
      return {
        ...state,
        bothDirections: action.bothDirections,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_QUESTIONS":
      return {
        ...state,
        questions: action.questions,
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "ADD_QUESTION":
      return {
        ...state,
        questions: [action.question, ...state.questions],
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "ERASE_QUESTION":
      return {
        ...state,
        questions: state.questions.filter((q, i) => i !== action.index),
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "EDIT_QUESTION":
      return {
        ...state,
        questions: state.questions.map((q, i) => {
          if (i === action.index) {
            return {
              ...q,
              question: action.question,
            };
          }
          return q;
        }),
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "EDIT_CORRECT":
      return {
        ...state,
        questions: state.questions.map((q, i) => {
          if (i === action.index) {
            return {
              ...q,
              correct: action.answer,
            };
          }
          return q;
        }),
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "EDIT_INCORRECT":
      return {
        ...state,
        questions: state.questions.map((q, i) => {
          if (i === action.index) {
            return {
              ...q,
              incorrect: q.incorrect.map((x, j) =>
                j === action.answerindex ? action.answer : x
              ),
            };
          }
          return q;
        }),
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_MAIN_TEXT_MP3S":
      return {
        ...state,
        mainMp3s: action.mainMp3s,
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_TEXTPIC":
      return {
        ...state,
        textpic: action.textpic,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "CLEAR_ANALYSIS":
      return {
        ...state,
        sentences: [],
        tokens: [],
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_TITLE":
      return {
        ...state,
        title: action.title,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_SENTENCE_TEXT":
      sentences = JSON.parse(JSON.stringify(state.sentences));
      if (!sentences[action.parindex]) {
        sentences[action.parindex] = [];
      }
      sentences[action.parindex][action.sentindex] = { text: action.text };

      return {
        ...state,
        sentences,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_SENTENCES":
      return {
        ...state,
        sentences: action.sentences,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_TRANSLATED_SENTENCE":
      sentences = JSON.parse(JSON.stringify(state.sentences));
      if (!sentences[action.parindex][action.sentindex].translations) {
        sentences[action.parindex][action.sentindex].translations = {};
      }
      sentences[action.parindex][action.sentindex].translations[
        action.language
      ] = action.translated;
      return {
        ...state,
        sentences,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_SENTENCE_MP3S":
      sentences = JSON.parse(JSON.stringify(state.sentences));
      sentences[action.parindex][action.sentindex].mp3s = action.mp3s;
      return {
        ...state,
        sentences,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_TOKENS":
      tokens = JSON.parse(JSON.stringify(state.tokens));
      if (!tokens[action.parindex]) {
        tokens[action.parindex] = [];
      }
      tokens[action.parindex][action.sentindex] = action.tokens;
      return {
        ...state,
        tokens,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "UPDATE_TOKENS":
      return {
        ...state,
        tokens: action.tokens,
      };
 
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "UPDATE_SENTENCES":
      return {
        ...state,
        sentences: action.sentences,
      };
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_TOKEN_MP3S":
      tokens = JSON.parse(JSON.stringify(state.tokens));
      tokens[action.parindex][action.sentindex][action.tokenindex].mp3s =
        action.mp3s;
      return {
        ...state,
        tokens,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_PARAGRAPHS":
      return {
        ...state,
        paragraphs: action.paragraphs,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "CLEAR_PROJECT":
      return initialState;

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_PROJECT":
      return {
        ...state,
        ...action.project,
      };
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_PROCESS_STATUS":
      return {
        ...state,
        projectstatus: action.projectstatus,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "INIT_PROJECT":
      return {
        ...state,
        ...zero,
        data: action.data,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_TRANSLATED_WORD":
      tokens = JSON.parse(JSON.stringify(state.tokens));
      if (
        !tokens[action.parindex][action.sentindex][action.tokenindex]
          .translations
      ) {
        tokens[action.parindex][action.sentindex][
          action.tokenindex
        ].translations = {};
      }
      tokens[action.parindex][action.sentindex][action.tokenindex].translations[
        action.language
      ] = action.translated;

      return {
        ...state,
        tokens,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_TOKEN":
      loc = action.location;
      tokens = JSON.parse(JSON.stringify(state.tokens));
      tokens[loc.parindex][loc.sentindex][loc.tokenindex] = action.token;
      return {
        ...state,
        tokens,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "SET_ILLUSTRATION":
      loc = action.location;
      tokens = JSON.parse(JSON.stringify(state.tokens));
      tokens[loc.parindex][loc.sentindex][loc.tokenindex].uri = action.uri;
      return {
        ...state,
        tokens,
      };

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "ADD_TO_PIC_BANK":
      return {
        ...state,
        picBank: state.picBank
          ? { ...state.picBank, [action.pictureid]: action.links }
          : { [action.pictureid]: action.links },
      };

    // --------------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
