import { getAuth, signOut } from "firebase/auth";


// -------------------------------------------------------
// -------------------------------------------------------
export const setProgressIndicator = (current, total, headline, status) => (dispatch) => {
  dispatch({type: "SET_PROGRESS_INDICATOR", current, total, headline, status});
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateProgressStatus = (status) => (dispatch) => {
  dispatch({type: "UPDATE_PROGRESS_STATUS", status});
};

// -------------------------------------------------------
// -------------------------------------------------------
export const showProgress = (show) => (dispatch) => {
  dispatch({type: "SHOW_HIDE_PROGRESS_INDICATOR", show});
}