import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// -----------------------------------------------------
import Frame from "Components/Frame/";
// -----------------------------------------------------
import { loadProject, eraseProject } from "Redux/project/actions";
import PleaseWait from "Components/PleaseWait";
// -----------------------------------------------------

// -----------------------------------------------------
// -----------------------------------------------------
const Project = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const project = useSelector((state) => state.project);

  const { pid } = useParams();
  console.log("pid", pid);

  useEffect(() => {
    console.log("loading project", pid);
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, []);

  if (!project || !hasLoaded) {
    return <PleaseWait />;
  }

  return (
    <Frame>
      <div style={{ position: "relative" }}>
        <div className="title is-5">{project.title}</div>
        <div style={{ position: "absolute", right: 8, top: 8 }}>
          <i
            style={{ marginLeft: "35px", fontSize: "1.5em" }}
            className="fa-duotone fa-trash-alt"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to erase this article? This can not be undone."
                )
              ) {
                dispatch(eraseProject(project.id));
                navigate("/");
              }
            }}
          />
        </div>
      </div>

      <div className="process-container">
        <CheckMark step="text">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("text")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-file-alt"></i>
            </span>
            <span>Edit Text</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="tokens">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("analysis")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-chart-network"></i>
            </span>
            <span>Grammatical Analysis</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="speech">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("speech")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-solid fa-volume"></i>
            </span>
            <span>Speech Generation</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="translations">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("translation")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-language"></i>
            </span>
            <span>Translation</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="textpic">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("text-illustration")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-paintbrush"></i>
            </span>
            <span>Illustrate Text</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="illustrations">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("token-illustration")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-image"></i>
            </span>
            <span>Illustrate Tokens</span>
          </button>
        </CheckMark>

        <br />
        <CheckMark step="quiz">
          <button
            className="button is-fullwidth"
            onClick={() => navigate("quiz")}
          >
            <span className="icon is-large">
              <i className="fa-duotone fa-hexagon-check"></i>
            </span>
            <span>Quiz</span>
          </button>
        </CheckMark>

        <br />
        <button
          className="button is-fullwidth"
          onClick={() => navigate("publish")}
        >
          <span className="icon is-large">
            <i className="fa-duotone fa-paper-plane"></i>
          </span>
          <span>Publish</span>
        </button>
      </div>
    </Frame>
  );
};

export default Project;

// -----------------------------------------------------
// -----------------------------------------------------
const CheckMark = ({ step, children }) => {
  const project = useSelector((state) => state.project);
  const { progress } = project;
  return (
    <div className="process-button-wrapper">
      {children}
      {progress[step] ? (
        <span className="icon is-large">
          <i className="fa-duotone fa-check-circle is-success"></i>
        </span>
      ) : (
        <span className="icon is-large">
          <i className="fa-thin fa-circle"></i>
        </span>
)}
    </div>
  );
};