import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addQuestion,
  eraseQuestion,
  editQuestion,
  editCorrect,
  editIncorrect,
} from "Redux/quiz/actions";


// -------------------------------------------------------------
// -------------------------------------------------------------
const Understanding = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const { paragraphs } = project;
  const text = paragraphs.join("\n");
  const questions = project.questions || [];

  return (
    <div>
      <div className = "add-question-wrapper">
      <div
        className={`button is-small ${loading && "is-loading"}`}
        onClick={() => {
          setLoading(true);
          dispatch(
            addQuestion(text, questions, () => {
              setLoading(false);
            })
          );
        }}
      >
        <span className="icon is-small">
          <i className="fa-duotone fa-plus" />
        </span>
        <span>Add Question</span>
      </div>
      </div>
      <div>
        {questions.map((question, index) => (
          <QuestionAndAnswers
            key={`question-${index}`}
            index={index}
            question={question}
          />
        ))}
      </div>
    </div>
  );
};

export default Understanding;

// -------------------------------------------------------------
// -------------------------------------------------------------
const QuestionAndAnswers = ({ question, index }) => {
  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <Question question={question.question} index={index} />
          <hr />
          <Correct answer={question.correct} index={index} />
          {question.incorrect.map((answer, answerindex) => (
            <Incorrect
              key={`answer-${index}-${answerindex}`}
              answer={answer}
              index={index}
              answerindex={answerindex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const Question = ({ question, index }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(question);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(question);
  }, [question]);

  const onSubmit = () => {
    dispatch(editQuestion(index, value));
    setEdit(false);
  };

  return (
    <div className="question-container">
      <div
        onClick={() => {
          setEdit(true);
        }}
      >
        {edit ? (
          <input
            className="input"
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            onKeyDown={(evt) => {
              evt.key === "Enter" && onSubmit();
            }}
            autoFocus
            onBlur={onSubmit}
          />
        ) : (
          <span>{question}</span>
        )}
      </div>
      <div>
        <button
          className="delete"
          onClick={() => {
            dispatch(eraseQuestion(index));
          }}
        />
      </div>
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Correct = ({ answer, index }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(answer);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const onSubmit = () => {
    dispatch(editCorrect(index, value));
    setEdit(false);
  };

  return (
    <div
      className="notification is-info is-light answer"
      onClick={() => {
        setEdit(true);
      }}
    >
      <span className="question-span">
        <i className="fa-duotone fa-check" />
      </span>
      {edit ? (
        <input
          className="input"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={(evt) => {
            evt.key === "Enter" && onSubmit();
          }}
          autoFocus
          onBlur={onSubmit}
        />
      ) : (
        <span>{answer}</span>
      )}
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Incorrect = ({ answer, index, answerindex }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(answer);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const onSubmit = () => {
    dispatch(editIncorrect(index, answerindex, value));
    setEdit(false);
  };

  return (
    <div
      className="notification is-light answer"
      onClick={() => {
        setEdit(true);
      }}
    >
      <span className="question-span">
        <i className="fa-duotone fa-ban" />
      </span>
      {edit ? (
        <input
          className="input"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={(evt) => {
            evt.key === "Enter" && onSubmit();
          }}
          autoFocus
          onBlur={onSubmit}
        />
      ) : (
        <span>{answer}</span>
      )}
    </div>
  );
};
