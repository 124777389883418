import { useSelector, useDispatch } from "react-redux";
// --------------------------------------------------------------
import { showProgress } from "Redux/progress/actions";
// --------------------------------------------------------------
import running from "Media/running.gif";

// --------------------------------------------------------------
// --------------------------------------------------------------
const ProgressIndicator = ({ onClose }) => {
    const progress = useSelector((state) => state.progress);
    const dispatch = useDispatch();
    if (!progress.show) return null;
    const { current, total, status, headline } = progress;
    const percentage = Math.round((current / total) * 100);
  
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{headline}</p>
            <button
              className="delete"
              onClick={() => {
                if(onClose) onClose();
                dispatch(showProgress(false));
              }}
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <div className="status-row">
              <img src={running} />
              &nbsp;&nbsp;<span>{status}</span>
            </div>
            <table className="table is-fullwidth" style = {{height: 5}}>
              <tbody>
                <tr style = {{height: "100%"}}>
                  <td className="progress-percentage">
                   <span>{percentage}%</span>
                  </td>
                  <td style = {{display: "flex", height: "100%", alignItems: "center", justifyContent: "center"}}>
                    <progress
                      className="progress is-primary is-small"
                      value={current}
                      max={total}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  };

  export default ProgressIndicator;