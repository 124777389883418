const analysisTabs = [
  {
    tab: "pos",
    headline: "Wortarten",
    iconclass: "fa-duotone fa-table-tree",
    active: true,
    cnt: 1,
  },
  {
    tab: "translation",
    headline: "Übersetzung",
    iconclass: "fa-duotone fa-earth-americas",
    cnt: 2,
    tabs: [
      {
        tab: "sentences",
        headline: "Sätze",
        iconclass: "fa-duotone fa-bars-staggered",
        active: true,
        cnt: 2,
      },
      {
        tab: "tokens",
        headline: "Wörter",
        iconclass: "fa-duotone fa-input-text",
        cnt: 3,
      },
    ],
  },
  {
    tab: "illustrations",
    headline: "Illustrierung",
    iconclass: "fa-duotone fa-paintbrush",
    cnt: 4,
    tabs: [
      {
        tab: "textpicture",
        headline: "Bild zum Text",
        iconclass: "fa-duotone fa-image",
        active: true,
        cnt: 4,
      },
      {
        tab: "illustration",
        headline: "Bilder zu den Wörtern",
        iconclass: "fa-duotone fa-images",
        cnt: 5,
      },
    ],
  },
  {
    tab: "speech",
    headline: "Aussprache",
    iconclass: "fa-duotone fa-microphone",
    cnt: 6,
  },
];

const initialTabs = [
  {
    tab: "text",
    headline: "Text",
    iconclass: "fa-duotone fa-file-lines",
    active: true,
    cnt: 0,
  },
  {
    tab: "analysis",
    headline: "Analyse",
    iconclass: "fa-duotone fa-chart-network",
    cnt: 1,
  },
  {
    tab: "preview",
    headline: "Vorschau",
    iconclass: "fa-duotone fa-eye",
    cnt: 1,
  },
  /*{
    tab: "illustrations",
    headline: "Illustrierung",
    iconclass: "fa-duotone fa-paintbrush",
    cnt: 4,
    tabs: [
      {
        tab: "textpicture",
        headline: "Bild zum Text",
        iconclass: "fa-duotone fa-image",
        active: true,
        cnt: 4,
      },
      {
        tab: "illustration",
        headline: "Bilder zu den Wörtern",
        iconclass: "fa-duotone fa-images",
        cnt: 5,
      },
    ],
  },*/

  {
    tab: "quiz",
    headline: "Quiz",
    iconclass: "fa-duotone fa-hexagon-check",
    cnt: 7,
    tabs: [
      {
        tab: "vocab",
        headline: "Vokabeln",
        iconclass: "fa-duotone fa-input-text",
        active: true,
        cnt: 7,
      },
      {
        tab: "understanding",
        headline: "Verständnisfragen",
        iconclass: "fa-duotone fa-lightbulb-exclamation-on",
        cnt: 8,
      },
    ],
  },
  {
    tab: "publish",
    headline: "Veröffentlichen",
    iconclass: "fa-duotone fa-paper-plane",
    cnt: 7,
  },
];

// -------------------------------------------------------
// -------------------------------------------------------
const getPath = (tabs, ancestor, path) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if (thisTab.active) {
      const hasSubTabs = thisTab.hasOwnProperty("tabs");
      path.push({
        ancestor,
        tab: thisTab.tab,
        headline: thisTab.headline,
        cnt: thisTab.cnt,
        hasSubTabs,
      });
      if (hasSubTabs) {
        getPath(thisTab.tabs, thisTab.tab, path);
      }
    }
  }
  return path;
};

// -------------------------------------------------------
// -------------------------------------------------------
const initialPath = getPath(initialTabs, "root", []);
const initialState = {
  tab: "banking",
  mainTab: "results",
  pickedTabs: ["collections", "norming"],
  mainTabs: initialTabs,
  path: initialPath,
  leaf: initialPath.at(-1).tab,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let mainTabs, path, leaf;
  // -------------------------------------------------------
  switch (action.type) {
    // --------------------------------------------------------------
    case "SET_ACTIVE_TAB":
      //console.log("SET_ACTIVE_TAB", action);
      mainTabs = setActive(
        action.current,
        action.ancestor,
        [...state.mainTabs],
        action.ancestor === "root"
      );
      path = getPath(mainTabs, "root", []);
      leaf = path.at(-1).tab;
      return {
        ...state,
        mainTabs,
        leaf,
        path,
      };

    // --------------------------------------------------------------
    case "INCLUDE_ANALYSIS_TABS":
      mainTabs = JSON.parse(JSON.stringify(state.mainTabs));
      mainTabs[1] = {
        ...mainTabs[1],
        tabs: analysisTabs,
      };
      mainTabs = setActive("pos", "revise", mainTabs, false);
      path = getPath(mainTabs, "root", []);
      leaf = path.at(-1).tab;

      return {
        ...state,
        mainTabs,
        leaf,
        path,
      };

    // -------------------------------------------------------
    default:
      return state;
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
const setActive = (currentTab, ancestor, tabs, ancestral) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    newTabs[ii] =
      thisTab.tab === currentTab
        ? { ...thisTab, active: true }
        : ancestral
        ? { ...thisTab, active: false }
        : thisTab;
    if (thisTab.tabs) {
      newTabs[ii].tabs = setActive(
        currentTab,
        ancestor,
        thisTab.tabs,
        thisTab.tab === ancestor
      );
    }
  }
  return newTabs;
};

export default reducer;
