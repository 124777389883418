import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
// -------------------------------------------------------------
import running from "Media/running.gif";
// -------------------------------------------------------------
import { findVocab, addVocab } from "Redux/quiz/actions";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Vocab = () => {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const { tokens, paragraphs} = project;
  const bothDirections = project.bothDirections || false;
  const text = paragraphs.join("\n");
  const vocab = project.vocab || [];

  const { leaf } = useSelector((state) => state.nav);
  const { progress } = project;
  const done = true; //progress[leaf];
  const enabled = true; //progress.text;

  const rightHandComponent = (
    <div className = "switch-container">
      Both Directions&nbsp;
      <Switch
        onChange={(checked) => {
          dispatch({
            type: "SET_BOTH_DIRECTIONS",
            bothDirections: checked,
          });
        }}
        checked={bothDirections}
      />
    </div>
  );

  return (
      <div className="columns">
        <div className="column">
        <div className="title is-5">
        Click on a word to add it to the quiz.
      </div>

          {tokens.map((tokenpar, parindex) => (
            <div key={`token-parindex-${parindex}`}>
              <div className="item-container">
                {tokenpar.map((tokensent, sentindex) =>
                  tokensent.map((token, index) => (
                    <Word
                      key={`${parindex}-${sentindex}-${index}`}
                      token={token}
                    />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="column box">
        <div className = "switch-container">
      <div style = {{flex: 3}}>Both Directions</div>
      <Switch
        onChange={(checked) => {
          dispatch({
            type: "SET_BOTH_DIRECTIONS",
            bothDirections: checked,
          });
        }}
        checked={bothDirections}
      />
      <hr />
      </div>

          <table className="table is-fullwidth is-bordered">
            <tbody>
              {vocab.map((token, tokenindex) => (
                <tr key={`vocab-${tokenindex}`}>
                  <td>
                    <VocabItem token={token} tokenindex={tokenindex} />
                  </td>
                  <Foils key={`foil-${tokenindex}`} token={token} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default Vocab;

// -------------------------------------------------------------
// ------------- Word  -----------------------------------------
const Word = ({ token }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="speech-item is-clickable"
      onClick={() => {
        dispatch(addVocab(token));
      }}
    >
      <div className="item-main">{token.token}</div>
    </div>
  );
};

// -------------------------------------------------------------
// ------------- VocabItem  -----------------------------------------
const VocabItem = ({ token, tokenindex }) => {
  const dispatch = useDispatch();
  return (
    <span className="tag is-warning is-light">
      {token.lemma}
      &nbsp;
      <button
        className="delete is-small"
        onClick={() => {
          dispatch({
            type: "REMOVE_VOCAB",
            tokenindex,
          });
        }}
      />
    </span>
  );
};

// -------------------------------------------------------------
// ------------- Foils  -----------------------------------------
const Foils__ = ({ token }) => {
  return (
    <td>
      <div>
        {token.foils ? token.foils.de.join(", ") : <img src={running} />}
      </div>
    </td>
  );
};

const Foils = ({ token }) => {
  return (
    <td>
      <div>
        {token.foils ? <div>
          <span className = "icon is-small">
            <i className="fa-duotone fa-check" />
          </span>
        </div> : <img src={running} />}
      </div>
    </td>
  );
};
