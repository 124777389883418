import { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
// -----------------------------------------------------
import logo from "Media/logo.png";
import background from "Media/background.jpg";
// -----------------------------------------------------
// -----------------------------------------------------
const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (evt) => {
    const { id, value } = evt.target;
    setData({ ...data, [id]: value });
  };

  const login = () => {
    setLoading(true);
    const auth = getAuth();
    const { email, password } = data;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error code:", errorCode);
        console.log("Error message:", errorMessage);
        switch (errorCode) {
          case "auth/user-not-found":
            setError("User not found.");
            break;
          default:
            setError("An error has occurred");
        }
      });
  };

  return (
    <div className="login-wrapper" style={{ 
      backgroundImage: `url("${background}")` 
    }}>

      <div className="box" style = {{padding: 75}}>
        <img src={logo} width="230" height="56" />
        <br />
        <hr />
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="email"
              placeholder="Email"
              value={data.email}
              id="email"
              onChange={onChange}
              autoFocus={true}
            />
            <span className="icon is-small is-left">
              <i className="fa-duotone fa-envelope"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              placeholder="Password"
              value={data.password}
              id="password"
              onChange={onChange}
            />
            <span className="icon is-small is-left">
              <i className="fa-duotone fa-lock"></i>
            </span>
          </p>
        </div>
        <br />
        <div className="buttons is-right">
          <button
            className={`button is-small  is-pulled-righ ${
              loading ? "is-loading" : ""
            }`}
            disabled={error !== null}
            onClick={login}
          >
            <span>Login</span>
            <span className="icon is-small">
              <i className="fa-duotone fa-right-to-bracket"></i>
            </span>
          </button>
        </div>
        {error ? (
          <div className="notification is-warning">
            {error}.{" "}
            <a
              onClick={() => {
                setData({ email: "", password: "" });
                setError(null);
              }}
            >
              OK
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Login;