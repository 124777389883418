import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// --------------------------------------------------------------
import ProgressIndicator from "Components/ProgressIndicator";
// --------------------------------------------------------------
import { signout } from "Redux/user/actions";
import { newProject } from "Redux/projects/actions";
// --------------------------------------------------------------
import smallLogo from "Media/logo-icon.png";
import fullLogo from "Media/logo.png";
// --------------------------------------------------------------
import Translate from "I18n/Translate";

// --------------------------------------------------------------
// --------------------------------------------------------------
const Frame = ({ children, frameType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tr = new Translate("Frame");

  const [menuOpen, setMenuOpen] = useState(false);
  const [generating, setGenerating] = useState(false);
  const { email, teachingLanguage } = useSelector((state) => state.user);
  const { version, versionnumber } = useSelector((state) => state.config);

  const navigateHome = () => {
    dispatch({ type: "CLEAR_PROJECT" });
    navigate("/");
  };

  const isAdmin = email === "jan.ihmels@gmail.com";

  const logo = frameType === "project" && false ? smallLogo : fullLogo;
  return (
    <div className="padding-8">
      <ProgressIndicator />
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className="navbar-item is-clickable" onClick={navigateHome}>
            <img src={logo} />
          </div>
          <div className="navbar-item">
            <div className="tag is-primary is-light">{teachingLanguage}</div>
          </div>
          {frameType === "home" && (
            <div className="navbar-item">
              <button
                className={`button is-small ${generating ? "is-loading" : ""}`}
                onClick={() => {
                  setGenerating(true);
                  dispatch(
                    newProject((projectid) => {
                      setGenerating(false);
                      navigate(`/project/${projectid}`);
                    })
                  );
                }}
              >
                <span className="icon is-small">
                  <i className="fa-duotone fa-plus-circle" />
                </span>
                <span>New Article</span>
              </button>
            </div>
          )}
          <a
            role="button"
            className={`navbar-burger ${menuOpen ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">{email.toLowerCase()}</a>

              <div className="navbar-dropdown is-right">
                <a
                  className="navbar-item"
                  onClick={() => {
                    dispatch({ type: "CLEAR_PROJECT" });
                    navigate("/");
                    dispatch(signout());
                  }}
                >
                  {tr.do("logout")}
                </a>
                <hr className="navbar-divider" />
                <a
                  className="navbar-item"
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  {tr.do("account")}
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  Version {version} ({versionnumber})
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="box main-container fade-between">{children}</div>
    </div>
  );
};
export default Frame;

const ProgressDisplay = () => {
  const project = useSelector((state) => state.project);
  const { progress } = project;
  let nChallenges = 0;
  if (progress.vocab) nChallenges += 2 * project.vocab.length;
  if (progress.understanding) nChallenges += project.questions.length;

  const progressQuiz =
    (progress.understanding || progress.vocab) && nChallenges > 0;
  const hasAll =
    progress.text &&
    progress.analysis &&
    progress.textpicture &&
    progress.illustration &&
    progressQuiz;

  return (
    <div className="tags has-addons tiny-tags">
      <ConditionalTag isFirst={true} isActive={progress.text}>
        Text
      </ConditionalTag>
      <ConditionalTag isActive={progress.analysis}>Analyse</ConditionalTag>
      <ConditionalTag isActive={progress.textpicture && progress.illustration}>
        Illustrierung
      </ConditionalTag>
      <ConditionalTag isActive={progressQuiz}>
        Quiz{progressQuiz ? <span>&nbsp;({nChallenges})</span> : null}
      </ConditionalTag>
    </div>
  );
};

const ConditionalTag = ({ isFirst, isActive, children }) => {
  const success = "is-success is-light";
  const left = isFirst ? "" : "left";
  if (isActive)
    return (
      <>
        <span className={`tag ${left} ${success}`}>{children}</span>
        <span className={`tag ${success}`}>
          <i className="fa-duotone fa-check" />
        </span>
      </>
    );
  return (
    <>
      <span className={`tag ${left}`}>{children}</span>
      <span className="tag">&nbsp;</span>
    </>
  );
};
