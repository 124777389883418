import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import mixpanel from "mixpanel-browser";
import dayjs from "dayjs";
import "dayjs/locale/de";
// ------------------------------------------------
import App from "./App";
import store from "System/reduxStore";
// ------------------------------------------------
import "Styles/bulma.min.css";
import "Fontawesome/css/all.css";
import "Styles/css.css";

// ------------------------------------------------
dayjs.locale("de");

// ------------------------------------------------
console.log("Initializing MP");
mixpanel.init("64be8677b41aead076e0d3f8ccb2448e", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

// ------------------------------------------------
// ------------------------------------------------
 const Main = () => (
  <Provider store={store}>
    <App />
  </Provider>
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
