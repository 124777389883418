import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import Vocab from "./Vocab/";
import Comprehension from "./Comprehension/";
import { loadProject, saveProject } from "Redux/project/actions";
import { generateQuiz } from "Redux/process/actions";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait";

// -----------------------------------------------------
const types = {
  vocab: {
    screen: <Vocab />,
    header: "Vocabulary",
    iconclass: "fa-duotone fa-input-text",
  },
  comprehension: {
    screen: <Comprehension />,
    header: "Comprehension",
    iconclass: "fa-duotone fa-lightbulb-exclamation-on",
  },
};

const quizTypes = ["vocab", "comprehension"];

// -----------------------------------------------------
// -----------------------------------------------------
const Quiz = () => {
  const dispatch = useDispatch();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [currentType, setCurrentType] = useState("vocab");
  const project = useSelector((state) => state.project);
  const { pid } = useParams();

  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  if (!hasLoaded || !project) {
    return <PleaseWait />;
  }

  let nChallenges = 0;
  const preFactor = project.bothDirections ? 2 : 1;
  if (project.vocab) nChallenges += preFactor * project.vocab.length;
  if (project.questions) nChallenges += project.questions.length;

  const hasQuiz = project.progress.quiz;

  return (
    <Frame>
      <Back>
        <button
          className={`button ${generating ? "is-loading" : ""}`}
          onClick={() => {
            setGenerating(true);
            dispatch(
              generateQuiz(() => {
                setGenerating(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Generate Quiz</span>
        </button>
      </Back>
      <hr />

      {hasQuiz && (
        <div>
          <div className="tabs">
            <ul>
              {quizTypes.map((quizType, index) => {
                const { header, iconclass } = types[quizType];
                return (
                  <li
                    key={index}
                    className={quizType === currentType ? "is-active" : ""}
                  >
                    <a onClick={() => setCurrentType(quizType)}>
                      <span className="icon is-small">
                        <i className={iconclass}></i>
                      </span>
                      <span>{header}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {types[currentType].screen}
        </div>
      )}
    </Frame>
  );
};

export default Quiz;
