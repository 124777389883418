import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------

// -------------------------------------------------------------
// -------------------------------------------------------------
const TextTitle = ({ suggestTitle }) => {
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);

  const { paragraphs } = project;

  useEffect(() => {
    setTitle(project.title);
  }, [project.title]);

  const submit = () => {
    dispatch({ type: "SET_TITLE", title });
    setEdit(false);
  };

  return (
    <>
      {edit ? (
        <div className={`field title-input`}>
          <div className="control">
            <input
              className="input is-large"
              type="text"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
              onBlur={submit}
              onKeyDown={(evt) => evt.key === "Enter" && submit()}
              autoFocus
            />
          </div>
        </div>
      ) : (
        <h4
          className={`title is-4 is-clickable`}
          onClick={() => {
            setEdit(true);
          }}
        >
          {title || " -- Klicken, um Titel hinzuzufügen -- "}
        </h4>

)}
    </>
  );
};

export default TextTitle;
