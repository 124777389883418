import { backendNode } from "System/system";
import store from "System/reduxStore";
import { nullProgress } from "./nullProgress";

// -------------------------------------------------------
// -------------------------------------------------------
export const saveProject = () => (dispatch) => {
  const data = store.getState();
  const project = JSON.parse(JSON.stringify(data.project));
  const { id } = project;
  project.modified = Date.now();
  if (project.hasOwnProperty("_id")) {
    delete project._id;
  }
  const projectString = JSON.stringify(project);
  backendNode("project", "save", { projectid: id, projectString });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const loadProject = (projectid, cb) => async (dispatch) => {
  const result = await backendNode("project", "load", { projectid });
  if (result.data) {
    const project = result.data;
    delete project._id;
    project.id = projectid;
    if (!project.progress) {
      project.progress = {
        text: true,
        tokens: false,
        speech: false,
        translations: false,
        textpic: false,
        illustrations: false,
        quizVocab: false,
        quizComprehension: false,      
      }
    }
    dispatch({ type: "SET_PROJECT", project });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const publishProject = (project, cb) => async (dispatch) => {
  dispatch({ type: "PUBLISH_PROJECT" });
  await backendNode("project", "publish", { projectid: project.id });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const rePublishProject = (project, cb) => async (dispatch) => {
  console.log("publishing project", project);
  dispatch({ type: "PUBLISH_PROJECT" });
  await backendNode("project", "republish", { projectid: project.id });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const eraseProject = (projectid) => async (dispatch) => {
  dispatch({ type: "ERASE_PROJECT", projectid });
  await backendNode("project", "erase", { projectid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateTokens = (tokens, completedCB) => async (dispatch) => {
  dispatch({ type: "UPDATE_TOKENS", tokens });
  completedCB();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const mergeTokens = (mergedLocations, cb) => async (dispatch) => {
  const project = store.getState().project;
  const tokens = JSON.parse(JSON.stringify(project.tokens));

  let newLemma, newPos, newToken;

  // find the first token to merge, and concatenate the lemmas and tokens of the rest
  let newLocation = null;
  tokens.forEach((par, parindex) => {
    par.forEach((sent, sentindex) => {
      sent.forEach((token, tokenindex) => {
        if (
          mergedLocations.includes(`${parindex}-${sentindex}-${tokenindex}`)
        ) {
          if (!newLocation) {
            newLocation = `${parindex}-${sentindex}-${tokenindex}`;
            newLemma = token.lemma;
            newPos = token.pos;
            newToken = token.token;
            return;
          }
          newLemma += ` ${token.lemma}`;
          newToken += ` ${token.token}`;
        }
      });
    });
  });
  
  // update the first token to be merged with the concatenated values
  const [parindex, sentindex, tokenindex] = newLocation.split("-");
  tokens[parindex][sentindex][tokenindex].lemma = newLemma;
  tokens[parindex][sentindex][tokenindex].pos = newPos;
  tokens[parindex][sentindex][tokenindex].token = newToken;
  
  tokens[parindex][sentindex][tokenindex].translations = null;
  tokens[parindex][sentindex][tokenindex].mp3s = null;

  // excise the other tokens that were merged
  mergedLocations = mergedLocations.filter((loc) => loc !== newLocation);
  const newTokens = tokens.map((par, parindex) =>
    par.map((sent, sentindex) =>
      sent.filter(
        (token, tokenindex) =>
          !mergedLocations.includes(`${parindex}-${sentindex}-${tokenindex}`)
      )
    )
  );

  dispatch({ type: "UPDATE_TOKENS", tokens: newTokens });
  cb();
};


// -------------------------------------------------------
// -------------------------------------------------------
export const splitTokens = (location) => async (dispatch) => {
  const project = store.getState().project;
  const tokens = JSON.parse(JSON.stringify(project.tokens));

  const [parindex, sentindex, tokenindex] = location.split("-");
  console.log("splitting", parindex, sentindex, tokenindex);

  const token = tokens[parindex][sentindex][tokenindex];
  let parts = token.token.split(" ");
  const mainLemma = token.lemma;
  let lemmaParts = token.lemma.split(" ");

  if(parts.length === 1) parts.push(token.token);

  const newTokens = [];
  for(const ii in parts){
    const part = parts[ii];
    const lemma = lemmaParts[ii] || mainLemma;
    console.log("part", part);
    newTokens.push({
      ...token,
      token: part,
      lemma,
      mp3s: null,
      translations: null,
    });
  }

  tokens[parindex][sentindex].splice(tokenindex, 1, ...newTokens);
  dispatch({ type: "UPDATE_TOKENS", tokens });
}
