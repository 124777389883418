import { getAuth, signOut } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { backendNode } from "System/system";
import store from "System/reduxStore";

// -------------------------------------------------------
// -------------------------------------------------------
export const signin = (user) => async (dispatch) => {

  mixpanel.identify(user.uid);  
  const res = await backendNode("user", "signin", {
    uid: user.uid,
    email: user.email,
  });
  if (res.data) {
    const thisUser = res.data.user;
    dispatch({
      type: "SET_USER",
      ...thisUser,
    });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const signout = () => (dispatch) => {
  mixpanel.reset();
  dispatch({ type: "SET_USER", uid: null, email: null });
  const auth = getAuth();
  signOut(auth);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const notify = (headline, content, duration) => (dispatch) => {};

// -------------------------------------------------------
// -------------------------------------------------------
export const setUILanguage = (uiLanguage) => (dispatch) => {
  dispatch({ type: "SET_UI_LANGUAGE", uiLanguage });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setTeachingLanguage = (teachingLanguage) => (dispatch) => {
  dispatch({ type: "SET_TEACHING_LANGUAGE", teachingLanguage });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const saveUserProfile = () => (dispatch) => {
  const user = store.getState().user;
  const { uid } = user;
  console.log("Saving User Profile", user);
  backendNode("user", "saveProfile", { uid, userString: JSON.stringify(user) });
};