import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import { loadProject, saveProject } from "Redux/project/actions";
import {generateSentenceSpeech, generateSpeech, generateTokenSpeech} from "Redux/process/actions";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait";
// -----------------------------------------------------

const speak = async (mp3s) => {
  const index = Math.floor(Math.random() * mp3s.length);
  const audio = new Audio(mp3s[index]);
  // wait until audio finishes playing
  await new Promise((resolve) => {
    audio.addEventListener("ended", resolve);
    audio.play();
  });
};

// -----------------------------------------------------
// -----------------------------------------------------
const Speech = () => {
  const dispatch = useDispatch();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [tab, setTab] = useState("sentences");

  const project = useSelector((state) => state.project);
  const { pid } = useParams();
  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  const tokens = JSON.parse(JSON.stringify(project.tokens || []));
  const sentences = JSON.parse(JSON.stringify(project.sentences || []));

  console.log("Sentences", sentences);
  if (!tokens || !hasLoaded || !tokens.length) {
    return <PleaseWait />;
  }

  const hasTokens = project.progress.tokens;
  const hasSpeech = project.progress.speech;

  if (!hasTokens) {
    return (
      <Frame>
        <Back />
        <div className="notification is-warning is-light">
          No tokens found. Please define a text and run the grammar analysis
          first.
        </div>
      </Frame>
    );
  }

  return (
    <Frame>
      <Back>
        <button
          className={`button ${generating ? "is-loading" : ""}`}
          onClick={() => {
            setGenerating(true);
            dispatch(
              generateSpeech(() => {
                setGenerating(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Generate Speech</span>
        </button>
      </Back>

      {hasSpeech && (
        <div className="tabs">
          <ul>
            <li className={`${tab === "tokens" ? "is-active" : ""}`}>
              <a onClick={() => setTab("tokens")}>Tokens</a>
            </li>
            <li className={`${tab === "sentences" ? "is-active" : ""}`}>
              <a onClick={() => setTab("sentences")}>Sentences</a>
            </li>
          </ul>
        </div>
      )}

      {hasSpeech && tab === "tokens" && <TokenSpeech tokens={tokens} />}
      {hasSpeech && tab === "sentences" && (
        <SentenceSpeech sentences={sentences} />
      )}
    </Frame>
  );
};

export default Speech;

// -----------------------------------------------------
// -----------------------------------------------------
const SentenceSpeech = ({ sentences }) => {
  const [currentSentence, setCurrentSentence] = useState(null);
  const dispatch = useDispatch();
  const [generating, setGenerating] = useState(false);

  const speakAll = async () => {
    for (let i = 0; i < sentences.length; i++) {
      for (let j = 0; j < sentences[i].length; j++) {
        const sentence = sentences[i][j];
        setCurrentSentence(`${i}-${j}`);
        if (sentence.mp3s) {
          await speak(sentence.mp3s);
        }
      }
    }
  };

  return (
    <div className="box">
      <div className="buttons are-small">
        <button className="button is-small" onClick={speakAll}>
          Read All Sentences
        </button>
      </div>
      {sentences.map((paragraph, parindex) => (
        <div key={parindex} className="box">
          {paragraph.map((sentence, index) => {
            const isCurrent = currentSentence === `${parindex}-${index}`;
            if (!sentence.mp3s)
              return (
                <button className="button is-small is-danger" onClick={() => {
                  setGenerating(true);
                  dispatch(
                    generateSentenceSpeech(parindex, index,() => {
                    setGenerating(false);
                    })
                  );
                }}>
                  Generate Sentence Speech
                </button>
              );
            return (
              <div key={index}>
                <div
                  className={`is-clickable sentence ${
                    isCurrent ? "is-current" : ""
                  }`}
                  onClick={() => {
                    if (sentence.mp3s) {
                      speak(sentence.mp3s);
                    }
                  }}
                >
                  {sentence.text}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

// -----------------------------------------------------
// -----------------------------------------------------
const TokenSpeech = ({ tokens }) => {
  const [currentToken, setCurrentToken] = useState(null);

  const speakAllTokens = async () => {
    for (let i = 0; i < tokens.length; i++) {
      for (let j = 0; j < tokens[i].length; j++) {
        for (let k = 0; k < tokens[i][j].length; k++) {
          const token = tokens[i][j][k];
          if (token.mp3s) {
            setCurrentToken(`${i}-${j}-${k}`);
            await speak(token.mp3s);
          }
        }
      }
    }
  };

  return (
    <div className="box">
      <div className="buttons are-small">
        <button className="button" onClick={speakAllTokens}>
          Read All Tokens
        </button>
      </div>

      {tokens.map((tokenpar, parindex) => (
        <div key={`parindex-${parindex}`}>
          <div>
            {tokenpar.map((tokensent, sentindex) => (
              <div key={`sentindex-${parindex}-${sentindex}`}>
                <div className="item-container">
                  {tokensent.map((token, tokenindex) => {
                    token = JSON.parse(JSON.stringify(token));
                    const thisToken = {
                      ...token,
                      parindex,
                      sentindex,
                      tokenindex,
                    };
                    return (
                      <Word
                        key={`token-${parindex}-${sentindex}-${tokenindex}`}
                        token={thisToken}
                        parindex={parindex}
                        sentindex={sentindex}
                        tokenindex={tokenindex}
                        isCurrent={
                          currentToken ===
                          `${parindex}-${sentindex}-${tokenindex}`
                        }
                        language={"en"}
                        onClick={(token) => {
                          speak(token.mp3s);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

// -------------------------------------------------------------
// ------------- Word  -----------------------------------------
const Word = ({ token, parindex, sentindex, tokenindex, onClick, isCurrent }) => {
  const dispatch = useDispatch();
  const [generating, setGenerating] = useState(false);
  return (
    <div
      className={`item ${isCurrent ? "picked" : ""}`}
      onClick={() => {
        onClick(token);
      }}
    >
      {token.mp3s ? (
        <>
          <div className="item-main">{token.token}</div>
          <div className="item-root">
            {token.pos !== "punctuation" ? token.lemma : "\u00A0"}
          </div>
        </>
      ) : (
        !["punctuation"].includes(token.pos) && (
          <button className="button is-small is-danger" onClick={() => {
             setGenerating(true);
             dispatch(
              generateTokenSpeech(parindex,sentindex, tokenindex,() => {
                setGenerating(false);
              })
            );
          }}>Generate Speech</button>
        )
      )}
    </div>
  );
};
