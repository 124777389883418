import { backendNode } from "System/system";
import store from "System/reduxStore";
import { initialState } from "Redux/project/reducer";

// -------------------------------------------------------
// -------------------------------------------------------
export const getProjects = (cb) => async (dispatch) => {
  const { teachingLanguage } = store.getState().user;
  const results = await backendNode("feed", "list", { language: teachingLanguage });
  if (results.data) {
    console.log("results", results.data);
    const { projects } = results.data;
    dispatch({ type: "SET_PROJECTS", projects });
  }
  if(cb) cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const newProject = (cb) => async (dispatch) => {
  const { uid, teachingLanguage } = store.getState().user;
  const empty = JSON.parse(JSON.stringify(initialState));
  const project = {
    ...empty,
    title: "New Article",
    setup: Date.now(),
    modified: Date.now(),
    uid,
    language: teachingLanguage,
    category: "world"
  };

  const response = await backendNode("feed", "setup", {
    projectString: JSON.stringify(project),
  });

  let projectid = null;
  if (response.data) {
    projectid = response.data.projectid;
    project.id = projectid;
    dispatch({ type: "SET_PROJECT", project });
    cb(projectid);
    return;
  }
  console.log("Error generating project");
  cb();
};
