import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------
import { illustrateToken, rePaint } from "Redux/illustration/actions";
// ------------------------------------------------
import { cloudinaryResize } from "Auxiliary/";
import CloudinaryUploadWidget from "Components/CloudinaryUploadWidget";
import {moveCloudinaryTokenImage} from "../../../Redux/process/actions";
import {saveProject} from "../../../Redux/project/actions";


const resize = (uri) => {
  const timestamp = new Date().getTime(); // Get the current timestamp
  return `${cloudinaryResize(uri, 300, 300)}?t=${timestamp}`;
};
// ------------------------------------------------
// ------------------------------------------------

const Edit = ({ onClose, editing }) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [repainting, setRepainting] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [cloudinaryPublicId, setCloudinaryPublicId] = useState("");
  const [uri, setUri] = useState("");
  const [uwConfig] = useState({
    cloudName: "dfumqwz6y",
    uploadPreset: "ygrol6ql",
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    // sources: [ "local", "url"], // restrict the upload sources to URL and local files
    // multiple: false,  //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    // clientAllowedFormats: ["images"], //restrict uploading to image files only
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });
  const project = useSelector((state) => state.project);
  const tokens = JSON.parse(JSON.stringify(project.tokens)) || [];
  const sentences = JSON.parse(JSON.stringify(project.sentences)) || [];

  const { parindex, sentindex, tokenindex } = editing;
  const location = { parindex, sentindex, tokenindex };
  const thisToken = JSON.parse(
    JSON.stringify(tokens[parindex][sentindex][tokenindex])
  );

  useEffect(() => {
    dispatch(saveProject());
    setUri(resize(project.tokens[parindex][sentindex][tokenindex].uri));
  }, [project]);

  useEffect(() => {
    setDescription(thisToken.description);
    const resized = resize(thisToken.uri);
    setUri(resized);
  }, [editing]);

  useEffect(() => {
      const tokenImagePublicId = extractUriParts(thisToken.uri);
      console.log("thisToken.uri", thisToken.uri);
      console.log("tokenImagePublicId", tokenImagePublicId);
      if (cloudinaryPublicId) {
          dispatch(
                  moveCloudinaryTokenImage(parindex, sentindex, tokenindex, cloudinaryPublicId, tokenImagePublicId, () => {
                  })
              );

      }
  }, [cloudinaryPublicId]);

  function extractUriParts(uri) {
  // Define the regex pattern to match the structure 'dictionary/<2_letter_lang_code>/<filename>'
  const regex = /dictionary\/([a-z]{2})\/([a-zA-Z0-9]+)\.[a-zA-Z]{3,4}$/;

  // Apply the regex to the URI
  const match = uri.match(regex);

  if (match) {
    // Reconstruct the required part
    const extractedPart = `dictionary/${match[1]}/${match[2]}`;
    return extractedPart;
  } else {
    throw new Error("The URI doesn't match the required pattern.");
  }
}


  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <b>{thisToken.lemma}</b> - {thisToken.translations.en}&nbsp;|&nbsp;
            <span className="small">{thisToken.pos}</span>
          </p>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>
        <section className="modal-card-body">
          <div
            className="box"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {uri ? (
              <img
                src={uri+"?t="+Date.now()}
                alt="token"
                style={{
                  width: 300,
                  height: 300,
                  borderRadius: 35,
                  border: "2px solid #ccc",
                  padding: 5,
                }}
              />
            ) : (
              <div
                style={{
                  height: 300,
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            )}
          </div>
          <div className="box">
            <textarea
              className="textarea"
              value={description}
              onChange={(evt) => {
                setDescription(evt.target.value);
              }}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <div className="buttons">
            <button
              className={`button ${generating ? "is-loading" : ""}`}
              onClick={() => {
                setGenerating(true);
                dispatch(illustrateToken(thisToken, location, (description, uri) => {
                  setGenerating(false);
                  setDescription(description);
                  setUri(resize(uri));
                }));
              }}
            >
              Generate Text & Image
            </button>
            <button
              className={`button ${repainting ? "is-loading" : ""}`}
              onClick={() => {
                setUri(null);
                setRepainting(true);
                dispatch(
                  rePaint(thisToken, description, location, (uri) => {
                    setRepainting(false);
                    setUri(resize(uri));
                  })
                );
              }}
            >
              Generate Image from Text
            </button>
              <CloudinaryUploadWidget
  uwConfig={uwConfig}
  setPublicId={setCloudinaryPublicId}
/>
            <button className="button" onClick={onClose}>
              Close
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Edit;
