import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//  ------------------------------------------------
import NewProject from "./NewProject";
import Frame from "Components/Frame";
import RoundButton from "Components/RoundButton";

import { getProjects } from "Redux/projects/actions";
//  ------------------------------------------------
//  ------------------------------------------------
const Home = () => {
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const { projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  console.log(projects);

  useEffect(() => {
    dispatch(
      getProjects(() => {
        setLoading(false);
      })
    );
  }, []);

  const onNewProject = () => {
    setPopup(true);
  };

  if (!projects) return <Frame>No Projects Found</Frame>;

  
  return (
    <Frame frameType ="home">
      {projects.length ? projects.map((item) => (
        <Item key={item._id} item={item} />
      )) : <div>No articles found</div>
    }
      {popup && (
        <NewProject
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
    </Frame>
  );
};

export default Home;

// ------------------------------------------------
// ------------------------------------------------
const Item = ({ item }) => {
  return (
    <Link to={`/project/${item._id}`}>
    <div className="box art-wrapper">
      <div className="art-image-wrapper">
        {item.textpic ? (
          <img
            src={item.textpic}
            alt={item.title}
            className="art-image"
            style={{ width: 90, height: 90 }}
          />
        ) : (
          <i className="fa-duotone fa-image" style={{ fontSize: 90 }}></i>
        )}
      </div>
      <div className="art-info">
        <h5 className="title is-5">{item.title}</h5>
        <p className="subtitle is-6">
          {new Date(item.setup).toLocaleDateString("de-DE")}
        </p>
        <div className="tags">
          {item.published ? (
            <div className="tag is-primary">
              <span className="icon">
                <i className="fa-duotone fa-check"></i>
              </span>
              <span>Published</span>
            </div>
          ) : (
            <div className="tag is-warning is-light">
              <span className="icon">
                <i className="fa-duotone fa-clock"></i>
              </span>
              <span>Unpublished</span>
            </div>
          )}
          {item.difficulty && (
            <div className="tag">
              <span>{item.difficulty}</span>
            </div>
          )}
          {item.category && (
            <div className="tag">
              <span>{item.category}</span>
            </div>
          )}
        </div>
      </div>
    </div>
    </Link>
  );
};
