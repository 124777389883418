import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import { loadProject, saveProject } from "Redux/project/actions";
import { generateTranslations } from "Redux/process/actions";
import { languages } from "Data/languages";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait";
import EditPopup from "./EditPopup";
// -----------------------------------------------------
// -----------------------------------------------------
const Translation = () => {
  const dispatch = useDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [currLanguage, setCurrLanguage] = useState(null);
  const [editingPopup, setEditingPopup] = useState(false);

  const project = useSelector((state) => state.project);

  const { pid } = useParams();
  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  const tokens = project.tokens || [];
  const sentences = project.sentences || [];

  const { teachingLanguage } = useSelector((state) => state.user);
  const langcodes = languages[teachingLanguage].map((x) => x.langcode);

  if (!tokens || !hasLoaded || !tokens.length) {
    return <PleaseWait />;
  }

  const hasTokens = project.progress.tokens;
  const hasTranslations = project.progress.translations;

  const edit = (location) => {
    const { parindex, sentindex, tokenindex } = location;
    const token = tokens[parindex][sentindex][tokenindex];
    const sentence = sentences[parindex][sentindex];
    setEditingPopup({location, token, sentence});
  }

  if (!hasTokens) {
    return (
      <Frame>
        <Back />
        <div className="notification is-warning is-light">
          No tokens found. Please define a text and run the grammar analysis
          first.
        </div>
      </Frame>
    );
  }

  return (
    <Frame>
      {editingPopup ? (
        <EditPopup
          onClose={() => setEditingPopup(null)}
          editing={editingPopup}
        />
      ) : null}

      <Back>
        <button
          className={`button ${generating ? "is-loading" : ""}`}
          onClick={() => {
            setGenerating(true);
            dispatch(
              generateTranslations(() => {
                setGenerating(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Generate Translations</span>
        </button>
      </Back>
      <div className="tags">
        {langcodes.map((langcode) => {
          const classNameAddition = langcode === currLanguage ? "" : "is-light";
          return (
            <span
              key={langcode}
              className={`tag is-large is-warning is-clickable ${classNameAddition}`}
              onClick={() =>
                setCurrLanguage(currLanguage === langcode ? null : langcode)
              }
            >
              {langcode}
            </span>
          );
        })}
      </div>
      {hasTranslations && (
        <div className="box">
          {tokens.map((tokenpar, parindex) => (
            <div key={`parindex-${parindex}`}>
              <div>
                {tokenpar.map((tokensent, sentindex) => (
                  <div key={`sentindex-${parindex}-${sentindex}`}>
                    <div className="item-container">
                      {tokensent.map((token, tokenindex) => {
                        token = JSON.parse(JSON.stringify(token));
                        const thisToken = {
                          ...token,
                          parindex,
                          sentindex,
                          tokenindex,
                        };
                        return (
                          <Word
                            key={`token-${parindex}-${sentindex}-${tokenindex}`}
                            token={thisToken}
                            language={currLanguage}
                            onClick={edit}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </Frame>
  );
};

export default Translation;

// -------------------------------------------------------------
// ------------- Word  -----------------------------------------
const Word = ({ token, onClick, language }) => {

  const { parindex, sentindex, tokenindex } = token;

  return (
    <div
      className="item"
      onClick={() => {
        onClick({ parindex, sentindex, tokenindex });
      }}
    >
      <div className="item-main">{token.token}</div>
      <div className="item-root">
        {token.pos !== "punctuation" ? token.lemma : "\u00A0"}
      </div>
      {token.translations ? (
        <>
          {" "}
          <div className="item-translate">
            <div className="tag is-small is-primary is-light">
              {token.translations["en"]}
            </div>
          </div>
          {language && (
            <div className="item-translate">
              <div className="tag is-small is-warning is-light">
                {token.translations[language]}
              </div>
            </div>
          )}
        </>
      ) : (
        <button className="button is-small is-danger">
          Generate Translations
        </button>
      )}
    </div>
  );
};
