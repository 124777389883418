import axios from "axios";
import queryString from "query-string";
import store from "./reduxStore";
// -------------------------------------------------------------
// -------------------------------------------------------------
const IS_LOCAL = window.location.href.slice(7, 16) === "localhost";

const backendHost =
  IS_LOCAL
    ? "http://localhost:3500"
    : "https://berrynoweb.lingoberry.co";

const pyHost =
  IS_LOCAL
    ? "http://localhost:3600" 
    : "https://berrypyweb.lingoberry.co";

// -------------------------------------------------------------
// -------------------------------------------------------------
export const backendNode = async (rubric, call, input) => {
  const { versionnumber } = store.getState().config;
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: backendHost + "/" + rubric + "/" + call,
    data: queryString.stringify({ ...input, versionnumber }),
  };
  const request = await axios(requestData);
  if (request.data && request.data.shouldUpdate) {
    if (
      window.confirm(
        "Eine neue Version dieser Seite ist verfügbar. Die Seite wird neu geladen."
      )
    ) {
      window.location.reload();
    }
  }
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const backendPy = (rubric, call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: pyHost + "/" + rubric + "/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const upload = async (formData, onProgress) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      onProgress(
        Math.round((100 * progressEvent.loaded) / progressEvent.total)
      );
    },
  };
  return axios.post(backendHost + "/upload", formData, config);
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const download = (projectid) => {
  let query = `?projectid=${encodeURI(projectid)}`;
  window.location.href = `${backendHost}/download/${query}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const downloadFile = (filepath, file) => {
  let query = `?filepath=${encodeURI(filepath)}&file=${encodeURI(file)}`;
  window.location.href = `${backendHost}/file${query}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const getStaticURI = (path) => {
  const rhs = path.split("birddata/")[1];
  return `${backendHost}${rhs}`;
};
