// -------------------------------------------------------
// -------------------------------------------------------
export const cloudinaryResize = (uri, width, height, gray) => {
  if (!uri) return null;
  const fragments = uri.split("/upload/");
  if (fragments.length === 2) {
    const [prefix, suffix] = fragments;
    uri = `${prefix}/upload/w_${width},h_${height}/${suffix}`;
    if(gray) uri = `${prefix}/upload/e_grayscale/w_${width},h_${height}/${suffix}`;
  }
  return uri;
};
