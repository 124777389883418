import { useNavigate } from "react-router-dom";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Back = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="buttons">
      <div className="button" onClick={() => navigate(-1)}>
        <span className="icon is-small">
          <i className="fa-duotone fa-caret-left"></i>
        </span>
        <span>Done</span>
      </div>
      {children}
    </div>
  );
};

export default Back;
