// --------------------------------------------------------------
// --------------------------------------------------------------
export const textLengths = [30, 50, 100, 150, 200, 300, 500];
export const difficulties = ["A1", "A2", "B1", "B2", "C1", "C2"];
export const difficulty_steps = [
  "Difficulty 1",
  "Difficulty 2",
  "Difficulty 3",
  "Difficulty 4",
  "Difficulty 5",
  "Difficulty 6",
];

export const uiLanguages = ["de", "en"];
export const taughtLanguages = ["de"];
export const taughtLanguagesAdmin = ["de", "he"];

// --------------------------------------------------------------
// --------------------------------------------------------------
export const directGroup = ["article", "conjunction", "pronoun", "preposition"];
export const complexGroup = ["noun", "verb", "adjective", "adverb"];


/*
// --------------------------------------------------------------
// --------------------------------------------------------------
export const languages = [
  { language: "Polnisch", langcode: "pl", translation: "Polski" },
  { language: "Rumänisch", langcode: "ro", translation: "Română" },
  { language: "Ukrainisch", langcode: "uk", translation: "українська" },
  { language: "Bulgarisch", langcode: "bg", translation: "Български" },
  { language: "Serbisch", langcode: "sr", translation: "Српски" },
  { language: "Kroatisch", langcode: "hr", translation: "Hrvatski" },
  { language: "Türkisch", langcode: "tr", translation: "Türkçe" },
  { language: "Albanisch", langcode: "sq", translation: "Shqip" },
  { language: "Persisch", langcode: "fa", translation: "فارسی" },
  { language: "Hausa", langcode: "he", translation: "Ivrit" },
  { language: "Pashto", langcode: "ps", translation: "پښتو" },
  { language: "Spanisch", langcode: "es", translation: "Español" },
  { language: "Italienisch", langcode: "it", translation: "Italiano" },
  { language: "Französisch", langcode: "fr", translation: "Français" },
  { language: "Niederländisch", langcode: "nl", translation: "Nederlands" },
  { language: "Arabisch", langcode: "ar", translation: "العربية" },
  { language: "Russisch", langcode: "ru", translation: "Русский" },
  { language: "Ungarisch", langcode: "hu", translation: "Magyar" },
];

// --------------------------------------------------------------
// --------------------------------------------------------------
export const languagesWithEnglish = [
  { language: "Englisch", langcode: "en", translation: "English" },
  ...languages,
];

// --------------------------------------------------------------
// --------------------------------------------------------------
export const allLanguages = [
  { language: "Deutsch", langcode: "de", translation: "Deutsch" },
  ...languagesWithEnglish,
];
*/