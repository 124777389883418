import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// -----------------------------------------------------
import Translate from "I18n/Translate";
// -----------------------------------------------------
import { setParagraphs, updateDifficulty } from "Redux/text/actions";
import GenerateText from "./GenerateText";
import Frame from "Components/Frame/";
import Back from "Components/Back/";
// -----------------------------------------------------
import TextTitle from "./TextTitle";
import { difficulties } from "Data";
import { saveProject } from "Redux/project/actions";
// -----------------------------------------------------

const Text = () => {
  const tr = new Translate("Text");
  const dispatch = useDispatch();

  const difficultyMapper = {
    A1: "A1 - Beginner",
    A2: "A2 - Beginner",
    B1: "B1 - Intermediate",
    B2: "B2 - Intermediate",
    C1: "C1 - Advanced",
    C2: "C2 - Advanced",
  };

  const [generate, setGenerate] = useState(false);
  const project = useSelector((state) => state.project);
  const paragraphs = project.paragraphs || [];
  const difficulty = difficultyMapper[project.difficulty] || null;
  const nWords = paragraphs.length ? paragraphs.join(" ").split(" ").length : 0;

  const direction = project.language === "he" ? "is-rtl" : "is-ltr";

  useEffect(() => {
    if (project.paragraphs) {
      const text = project.paragraphs.join("\n\n").trim();
      dispatch(updateDifficulty(text));
    }
  }, [project.paragraphs]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  return (
    <Frame>
      <div className="top-row">
        <Back />
        <div className="tags">
          {nWords > 0 && (
            <div className="tag is-info is-light">{nWords} Words</div>
          )}
          {difficulty && (
            <div className="tag is-warning is-light">{difficulty}</div>
          )}
        </div>
      </div>
      <div className={direction}>
        {generate ? <GenerateText onClose={() => setGenerate(false)} /> : null}
        <hr />
        <TextTitle suggestTitle={true} />
        <hr />
        <Paragraphs />
        <hr />
        {!paragraphs || paragraphs.length === 0 ? (
          <div className="buttons">
            <div className="button" onClick={() => setGenerate(true)}>
              <span className="icon">
                <i className="fa-duotone fa-sparkles" />
              </span>
              <span>Add External Text</span>
            </div>
          </div>
        ) : null}
      </div>
    </Frame>
  );
};

export default Text;

// -------------------------------------------------------------
// -------------------------------------------------------------
const Paragraphs = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");
  const project = useSelector((state) => state.project);
  const paragraphs = project.paragraphs || [];

  useEffect(() => {
    const paragraphs = project.paragraphs || [];
    const thisText = paragraphs.join("\n");
    setText(thisText);
    setEdit(!thisText);
  }, [project.paragraphs]);

  const submit = () => {
    setEdit(false);
    const paragraphs = text.split("\n").filter((p) => p.length > 0);
    dispatch({ type: "CLEAR_ANALYSIS" });
    dispatch(setParagraphs(paragraphs));
  };

  if (edit) {
    return (
      <div className="field">
        <div className="control">
          <textarea
            placeholder="Text to be published"
            className="textarea input-text"
            onChange={(evt) => setText(evt.target.value)}
            onBlur={submit}
            value={text}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="box" onClick={() => setEdit(true)}>
      {paragraphs.map((paragraph, index) => {
        return (
          <p className="p-paragraph" key={`paragraph-${index}`}>
            {paragraph}
          </p>
        );
      })}
    </div>
  );
};