export const initialState = {
  stats: []
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {

  // -------------------------------------------------------
  switch (action.type) {
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    case "FEEDBACK_SET_STATS":
      return {
        ...state,
        project: action.project,
        stats: action.stats,
      };
    // --------------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
