import LocalizedStrings from "react-localization";
import store from "System/reduxStore";
// --------------------------------------------------------------
import en from "./en.json";
import de from "./de.json";
// --------------------------------------------------------------

const input = {
  en,
  de,
};

// --------------------------------------------------------------
// --------------------------------------------------------------
class Translate {
  constructor(area) {
    const { user } = store.getState();
    const language = user.uiLanguage || "en";
    this.area = area;
    this.strings = new LocalizedStrings(input);
    this.strings.setLanguage(language);
  }

  do(key) {
    const fullKey = this.area + "_" + key;
    return this.strings[fullKey] || key;
  }
}

export default Translate;
