const initialState = {
  courses: [],
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_COURSES":
      return {
        ...state,
        courses: action.courses,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "ERASE_COURSE":
      return {
        ...state,
        courses: state.courses.filter(
          (course) => course._id !== action.courseid
        ),
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_COURSE":
      return {
        ...state,
        course: action.course,
      };
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_COURSE_HEADLINE":
      return {
        ...state,
        course: {
          ...state.course,
          headline: action.headline,
        },
      };

    // --------------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
