import {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// ------------------------------------------------

// ------------------------------------------------
// ------------------------------------------------
const GLOSSARY = {
    "ADJ": "adjective",
    "ADP": "adposition",
    "ADV": "adverb",
    "AUX": "auxiliary",
    "CONJ": "conjunction",
    "CCONJ": "coordinating conjunction",
    "DET": "determiner",
    "INTJ": "interjection",
    "NOUN": "noun",
    "NUM": "numeral",
    "PART": "particle",
    "PRON": "pronoun",
    "PROPN": "proper noun",
    "PUNCT": "punctuation",
    "SCONJ": "subordinating conjunction",
    "SYM": "symbol",
    "VERB": "verb",
    "X": "other",
    "EOL": "end of line",
    "SPACE": "space",
}


const Edit = ({ onClose, editing }) => {
  const dispatch = useDispatch();
  
  const project = useSelector((state) => state.project);
  const tokens = JSON.parse(JSON.stringify(project.tokens)) || [];
  const sentences = JSON.parse(JSON.stringify(project.sentences)) || [];

  const thisToken = editing.token;
  const thisSentence = editing.sentence;
  const thisLocation = editing.location;

  console.log(thisLocation);

  const [hasChanged, setHasChanged] = useState(false);
  const [pos, setPOS] = useState(thisToken.pos);
  const [universalPOS, setUniversalPOS] = useState(thisToken._pos);
  const [lemma, setLemma] = useState(thisToken.lemma);
  const [token, setToken] = useState(thisToken.token);


  useEffect(() => {
    const universalPOS = Object.keys(GLOSSARY).find(key => GLOSSARY[key] === pos);
    if (universalPOS) {
      setUniversalPOS(universalPOS);
    }
  }, [pos]);

  const saveChanges = () => {
    const { parindex, sentindex, tokenindex } = thisLocation;
    tokens[parindex][sentindex][tokenindex] = {
      ...tokens[parindex][sentindex][tokenindex],
      pos,
      _pos: universalPOS,
      lemma,
      token,
      mp3s: null,
      translations: null,
    };
    dispatch({ type: "UPDATE_TOKENS", tokens });
    if (thisSentence.text) {
      const newSentence = thisSentence.text.replace(thisToken.token, token);
      sentences[parindex][sentindex].text = newSentence;
      sentences[parindex][sentindex].mp3s = null;
      dispatch({ type: "UPDATE_SENTENCES", sentences });
    }
    onClose();
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Token</p>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>
        <section className="modal-card-body">
          <div className="box">
            <table className="table">
              <tbody>
              <tr>
                <td>POS</td>
                <td>
                  <select
                    className="select"
                    value={pos}
                    onChange={(value) => {
                      if (value !== pos) setHasChanged(true);
                      setPOS(value.target.value);
                    }}
                  >
                    {Object.entries(GLOSSARY).map(([key, value]) => (<option key={key} value={value}>{value}</option>))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Token</td>
                <td>
                  <input
                    className="input"
                    type="text"
                    value={token}
                    onChange={(evt) => {
                      if (evt.target.value !== token) setHasChanged(true);
                      setToken(evt.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Lemma</td>
                <td>
                  <input
                    className="input"
                    type="text"
                    value={lemma}
                    onChange={(evt) => {
                      if (evt.target.value !== lemma) setHasChanged(true);
                      setLemma(evt.target.value);
                    }}
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="box">{thisSentence.text}</div>
        </section>
        <footer className="modal-card-foot">
          <div className="buttons">
            {hasChanged ? (
              <button className="button is-success" onClick={saveChanges}>
                Save Changes
              </button>
            ) : (
              <button className="button is-success" disabled>
                Save Changes
              </button>
            )}
            <button className="button" onClick={onClose}>
              Close
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Edit;
