import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -----------------------------------------------------
import { cloudinaryResize } from "Auxiliary/";
import { loadProject, saveProject } from "Redux/project/actions";
import { illustrateTokens } from "Redux/process/actions";
// -----------------------------------------------------
import Frame from "Components/Frame/";
import Back from "Components/Back/";
import PleaseWait from "Components/PleaseWait/";
import EditPopup from "./EditPopup";
// -----------------------------------------------------
const resize = (uri) => {
  return cloudinaryResize(uri, 300, 300);
};

// -----------------------------------------------------
// -----------------------------------------------------
const TokenIllustration = () => {
  const dispatch = useDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [redoing, setRedoing] = useState(false);
  const [editingPopup, setEditingPopup] = useState(false);
  const project = useSelector((state) => state.project);
  const { teachingLanguage } = useSelector((state) => state.user);
  const direction = teachingLanguage === "he" ? "is-rtl" : "is-ltr";
  const { pid } = useParams();
  const tokens = project.tokens || [];

  useEffect(() => {
    dispatch(
      loadProject(pid, () => {
        setHasLoaded(true);
      })
    );
  }, [pid]);

  useEffect(() => {
    dispatch(saveProject());
  }, [project]);

  if (!hasLoaded) {
    return <PleaseWait />;
  }

  return (
    <Frame>
      <Back>
        <button
          className={`button ${redoing ? "is-loading" : ""}`}
          onClick={() => {
            setRedoing(true);
            dispatch(
              illustrateTokens(() => {
                setRedoing(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-solid fa-arrow-progress"></i>
          </span>
          <span>Illustrate Tokens</span>
        </button>
      </Back>

      {editingPopup ? (
        <EditPopup onClose={() => setEditingPopup(null)} editing={editingPopup} />
      ) : null}

      {project.progress.illustrations && (
        <div className={`box ${direction}`}>
          {tokens.map((tokenpar, parindex) => (
            <div key={`parindex-${parindex}`}>
              <div>
                {tokenpar.map((tokensent, sentindex) => (
                  <div key={`sentindex-${parindex}-${sentindex}`}>
                    <div className="item-container">
                      {tokensent.map((token, tokenindex) => {
                        token = JSON.parse(JSON.stringify(token));
                        const thisToken = {
                          ...token,
                          parindex,
                          sentindex,
                          tokenindex,
                        };
                        return (
                          <Word
                            key={`token-${parindex}-${sentindex}-${tokenindex}`}
                            token={thisToken}
                            onClick={(location) => {
                              setEditingPopup(location);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </Frame>
  );
};

export default TokenIllustration;

// -------------------------------------------------------------
// ------------- Word  -----------------------------------------
const Word = ({ token, onClick, language }) => {
  
  if(!token.translations || !token.translations["en"]) {
    return <div className = "tag is-danger">No English Translation</div>
  }

  const { parindex, sentindex, tokenindex } = token;
  return (
    <div
      className="item"
      onClick={() => {
        onClick({ parindex, sentindex, tokenindex });
      }}
    >
      <div className="item-pos">
        {token.pos !== "punctuation" ? token.pos : "\u00A0"}
      </div>
      <div className="item-main">{token.token}</div>
      <div className="item-root">
        {token.pos !== "punctuation" ? token.lemma : "\u00A0"}
      </div>
      <div className="item-translate">{token.translations["en"]}</div>
      {language && (
        <div className="item-translate">{token.translations[language]}</div>
      )}
      {token.uri ? (
        <div className="item-picture">
          <img src={resize(token.uri)} />
        </div>
      ) : (
        ["punctuation", "numeral", "proper noun"].includes(token.pos) ? 
          <div className="item-placeholder" /> :
          <button className = "button is-small is-danger">Generate Illustration</button>
      )}
    </div>
  );
};
