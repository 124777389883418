import { backendNode, backendPy } from "System/system";
import { textLengths, difficulties } from "Data/";
import { setProgress, resetProgress } from "Redux/nav/actions";
import store from "System/reduxStore";

// -----------------------------------------------------
// ------------------- generateText -------------------
export const generateText = (data, cb) => async (dispatch) => {
  console.log(data);
  
  dispatch({ type: "INIT_PROJECT", data });
  
  const nWords = textLengths[data.textlength];
  const cefr = difficulties[data.difficulty];

  let prompt = "You are a bot that writes texts in German for language learners.  The texts vary in difficulty between A1 and C2, based on the CEFR standard." 
  prompt += data.topic ? 
    `\nPlease write a short text on the topic "${data.topic}" at the ${cefr} level.` : 
    `\nPlease write a short text at the ${cefr} level.`;
  prompt += data.keywords ? `\nInclude the following keywords: ${data.keywords}.` : "";
  prompt += data.vocabulary ? `\nInclude the following vocabulary: ${data.vocabulary}.` : "";
  prompt += `\nAim for ${nWords} words.`;

  let res = await backendNode("classroom", "query", { prompt });
  if(res.data) {
    // remove quote encapsulating the entire text, i.e. at beginning and end
    let text = res.data.reply.replace(/^"|"$/g, "");
    // replace remaining quotes with backticks
    text = text.replace(/"/g, "`");
    const paragraphs = text.split("\n\n");
    dispatch(setParagraphs(paragraphs));
    prompt = `Find a title in German for the following text. Please make the title very short and very simple. \n\n${text}`;
    res = await backendNode("classroom", "query", { prompt });
    if (res.data) {
      const title = res.data.reply.replace(/"/g, "");
      dispatch({ type: "SET_TITLE", title });
      dispatch(checkForCompletion(paragraphs, title));
    }
  }
  cb();
};

// -----------------------------------------------------
// -----------------------------------------------------
export const setParagraphs = (paragraphs) => (dispatch) => {
  dispatch({ type: "SET_PARAGRAPHS", paragraphs });
  const text = paragraphs.join("\n\n").trim();
  const { title } = store.getState().project;
  dispatch(checkForCompletion(paragraphs, title));
}

export const checkForCompletion = (paragraphs, title) => dispatch => {
  if (paragraphs.length > 0 && title) {
    dispatch({ type: "SET_PROCESSING_STAGE", stage: 1 });
    dispatch({ type: "CLEAR_PROJECT_STATUS" });
  }
}

// -----------------------------------------------------
// -----------------------------------------------------
export const updateDifficulty = text => async dispatch => {
  console.log("Updating difficulty")
  const res = await backendPy("level", "determine", {text});
  if (res.data) {
    console.log(res.data);
    const difficulty = res.data.result;
    console.log("Difficulty is", difficulty);
    const textlength = text.split(" ").length;
    console.log("Word count", text.split(" ").length);
    dispatch({ type: "SET_PROJECT_DIFFICULTY", difficulty });
    dispatch({ type: "SET_PROJECT_TEXTLENGTH", textlength });

  }
}


// -----------------------------------------------------
// ------------------- generateText -------------------
export const rewriteText = (data, cb) => async (dispatch) => {

  const { teachingLanguage } = store.getState().user;

  const languages = {
    "de": "German",
    "he": "Hebrew",
  }
  const language = languages[teachingLanguage];

  dispatch({ type: "INIT_PROJECT", data });
  
  const nWords = textLengths[data.textlength];
  const cefr = difficulties[data.difficulty];

  let prompt = `The following text is taken from a newspaper.
    It is written for native speakers of ${language}. 
    The level is too difficult for language learners. 
    Please rewrite the text to make it appropriate for readers who are learning ${language}.
    This means, use simpler language, simplify complicated sentences and use more basic vocabulary. 
    Please condense the content so that only the essential parts of the text are retained. 
    However, it is important to keep the text in the style of a newspaper article.
    In some cases, it may be necessary to add information to make the text easier to understand.
    
    Please aim for the CEFT level ${cefr}.
    Please aim for a total length of approximately ${nWords} words.
    This is the text: \n\n${data.text}`;

    
  let res = await backendNode("classroom", "query", { prompt });
  if(res.data) {
    // remove quote encapsulating the entire text, i.e. at beginning and end
    let text = res.data.reply.replace(/^"|"$/g, "");
    console.log(text);
    // replace remaining quotes with backticks
    text = text.replace(/"/g, "`");
    const paragraphs = text.split("\n\n");
    dispatch(setParagraphs(paragraphs));
    prompt = `Find a title in ${language} for the following text. 
      The title should be in the style of a newspaper article headline.
      \n\nThis is the text: ${text}`;

    res = await backendNode("classroom", "query", { prompt });
    if (res.data) {
      const title = res.data.reply.replace(/"/g, "");
      dispatch({ type: "SET_TITLE", title });
      dispatch(checkForCompletion(paragraphs, title));
    }
  }
  cb();
};

