import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from 'redux-devtools-extension';

// ----------------- Reducers ----------------- //
import user from "Redux/user/reducer";
import project from "Redux/project/reducer";
import projects from "Redux/projects/reducer";
import courses from "Redux/courses/reducer";
//import translation from "Redux/translation/reducer";
import config from "Redux/config/reducer";
import progress from "Redux/progress/reducer";
import nav from "Redux/nav/reducer";
//import analysis from "Redux/analysis/reducer";
import feedback from "Redux/feedback/reducer";

// ----------------- Store ----------------- //
const store = configureStore({
  reducer: {
    user,
    project,
    projects,
    courses,
    //translation,
    config,
    progress,
    //analysis,
    nav,
    feedback,
  },
  devTools: true,
}, composeWithDevTools());
export default store;