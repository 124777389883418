const initialState = {
  projects: [],
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    // -------------------------------------------------------
    case "CLEAR_PROJECTS":
      return initialState;

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "SET_PROJECTS":
      return {
        ...state,
        projects: action.projects,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    case "ERASE_PROJECT":
      return {
        ...state,
        projects: state.projects.filter(
          (project) => project._id !== action.projectid
        ),
      };

    // --------------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
