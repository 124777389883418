import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import Frame from "Components/Frame";
import { taughtLanguages, taughtLanguagesAdmin } from "Data";
import { langcode2language } from "Data/languages";
// -------------------------------------------------------------
import { setUILanguage, setTeachingLanguage } from "Redux/user/actions";
import { saveUserProfile } from "Redux/user/actions";
// --------------------------------------------------------------
import Translate from "I18n/Translate";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Account = () => {

  const tr = new Translate("Account");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // autosave user profile on change
  useEffect(() => {
    if(user) {
      dispatch(saveUserProfile());
    }
  }, [user]);

  const { email, teachingLanguage, uiLanguage } = user;
  const { isAdmin } = { isAdmin: true };
  const taught = isAdmin ? taughtLanguagesAdmin : taughtLanguages;

  return (
    <Frame>
      <div className="title is-4">{tr.do("account")}</div>
      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                <b>Email</b>
              </td>
              <td>{email}</td>
            </tr>
            <tr>
              <td>
                <b>{tr.do("taughtlanguage")}</b>
              </td>
              {isAdmin ? (
                <td>
                  <div className="select">
                    <select
                      value={teachingLanguage}
                      onChange={(evt) => {
                        dispatch(setTeachingLanguage(evt.target.value));
                      }}
                    >
                      {taught.map((lang) => (
                        <option key={`taught-${lang}`} value={lang}>
                          {langcode2language[lang]}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
              ) : (
                <td>{langcode2language[teachingLanguage]}</td>
              )}
            </tr>
            <tr>
              <td>
                <b>{tr.do("userlanguage")}</b>
              </td>
              <td>
                <div className="select">
                  <select
                    value={uiLanguage}
                    onChange={(evt) => {
                      dispatch(setUILanguage(evt.target.value));
                    }}
                  >
                    <option value="de">Deutsch</option>
                    <option value="en">English</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Frame>
  );
};

export default Account;
