import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// ------------------------------------------------
import { initFirebase } from "System/firebase";
// ------------------------------------------------
import { signin, signout } from "Redux/user/actions";
import waitbar from "Media/waitbar.gif";
// ------------------------------------------------


import Login from "Login";
// ------------------------------------------------
import Home from "Home";
import Account from "Account";
// ------------------------------------------------
import Project from "Project";
import Text from "Project/Text";
import Analysis from "Project/Analysis";
import Speech from "Project/Speech";
import Translation from "Project/Translation";
import TextIllustration from "Project/TextIllustration";
import TokenIllustration from "Project/TokenIllustration";
import Quiz from "Project/Quiz";
import Publish from "Project/Publish";


// ------------------------------------------------
const routerOuter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

// ------------------------------------------------
const routerInner = createBrowserRouter([
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/project/:pid",
    element: <Project />,
  },
  {
    path: "/project/:pid/text",
    element: <Text />,
  },
  {
    path: "/project/:pid/analysis",
    element: <Analysis />,
  },
  {
    path: "/project/:pid/speech",
    element: <Speech />,
  },
  {
    path: "/project/:pid/translation",
    element: <Translation />,
  },
  {
    path: "/project/:pid/text-illustration",
    element: <TextIllustration />,
  },
  {
    path: "/project/:pid/token-illustration",
    element: <TokenIllustration />,
  },
  {
    path: "/project/:pid/quiz",
    element: <Quiz />,
  },
  {
    path: "/project/:pid/publish",
    element: <Publish />,
  },
]);


// ------------------------------------------------
// ------------------------------------------------
const App = () => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.user);

  useEffect(() => {
    initFirebase();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(signin(user));
      } else {
        dispatch(signout());
      }
    });
  }, []);

  if (uid === undefined) {
    return (
      <div className="loading">
        <img src={waitbar} />
      </div>
    );
  }

  const router = uid ? routerInner : routerOuter;
  return <RouterProvider router={router} />;
};

export default App;
// ------------------------------------------------
