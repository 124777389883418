import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// -----------------------------------------------------
import Translate from "I18n/Translate";
// -------------------------------------------------------------
import { rewriteText } from "Redux/text/actions";
// -------------------------------------------------------------
import { textLengths, difficulty_steps } from "Data/";

// -------------------------------------------------------------
// -------------------------------------------------------------
const GenerateText = ({ onClose }) => {
  const tr = new Translate("Text");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const project = useSelector((state) => state.project);

  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      ...project.data,
    });
  }, [project.data]);

  const onChange = (evt) => {
    setData({
      ...data,
      [evt.target.id]: evt.target.value,
    });
  };

  if (data === null) return null;
  const canGenerate = data.text !== "";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Rewrite</p>
          <button className="delete" onClick={onClose} aria-label="close" />
        </header>
        <section className="modal-card-body">
          <div>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Text Length</label>
                    <div className="control">
                      <div className="select">
                        <select
                          id="textlength"
                          value={data["textlength"]}
                          onChange={onChange}
                        >
                          {textLengths.map((length, index) => (
                            <option value={index} key={length}>
                              {length} {tr.do("words")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Difficulty</label>
                    <div className="control">
                      <div className="select">
                        <select id="difficulty" onChange={onChange}>
                          {difficulty_steps.map((difficulty, index) => (
                            <option value={index} key={difficulty}>
                              {difficulty}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <textarea
                className="textarea"
                placeholder="Paste Text"
                id="text"
                onChange={onChange}
              >
                {data.text}
              </textarea>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-primary ${
              loading ? "is-loading" : "is-light"
            }`}
            disabled={!canGenerate}
            onClick={() => {
              setLoading(true);
              dispatch(
                rewriteText(data, () => {
                  setLoading(false);
                  onClose();
                })
              );
            }}
          >
            <span className="large-icon">
              <i className="fa-duotone fa-feather"></i>
            </span>
            <span>Rewrite Text</span>
          </button>
        </footer>
      </div>
    </div>
  );
};

export default GenerateText;
