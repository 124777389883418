const initialState = {
  show: null,
  total: 30,
  current: 10,
  status: "Finding image for main text",
  headline: "Generating image",
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    case "SHOW_HIDE_PROGRESS_INDICATOR":
      return {
        ...state,
        show: action.show ? 9 : null,
      };
    case "UPDATE_PROGRESS_STATUS":
      return {
        ...state,
        status: action.status,
      };
    case "SET_PROGRESS_INDICATOR":
      return {
        ...state,
        status: action.status,
        headline: action.headline,
        current: action.current,
        total: action.total,
      };
    default:
      return state;
  }
};

export default reducer;
