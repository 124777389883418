const initialState = {
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        uid: action.uid,
        email: action.email,
        isAdmin: action.isAdmin,
        teachingLanguage: action.teachingLanguage,
        uiLanguage: action.uiLanguage,
      };

    case "SET_UI_LANGUAGE":
      return {
        ...state,
        uiLanguage: action.uiLanguage,
      };

    case "SET_TEACHING_LANGUAGE":
      return {
        ...state,
        teachingLanguage: action.teachingLanguage,
      };

    default:
      return state;
  }
};

export default reducer;
