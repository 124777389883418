// --------------------------------------------------------------
// --------------------------------------------------------------
export const languages = {
  de: [
    { langcode: "es", translation: "Español" },
    { langcode: "it", translation: "Italiano" },
    { langcode: "pl", translation: "Polski" },
    { langcode: "ro", translation: "Română" },
    { langcode: "uk", translation: "Українська" },
    { langcode: "bg", translation: "Български" },
    { langcode: "ar", translation: "العربية" },
    { langcode: "ru", translation: "Русский" },
    { langcode: "fa", translation: "فارسی" },
    { langcode: "sr", translation: "Српски" },
    { langcode: "ps", translation: "پښتو" },
    { langcode: "hr", translation: "Hrvatski" },
    { langcode: "tr", translation: "Türkçe" },
    { langcode: "he", translation: "עברית" },
    { langcode: "fr", translation: "Français" },
    { langcode: "nl", translation: "Nederlands" },
  ],
  he: [
    { langcode: "ru", translation: "Русский" }, // Russian
    { langcode: "fr", translation: "Français" }, // French
    { langcode: "es", translation: "Español" }, // Spanish
    { langcode: "de", translation: "Deutsch" }, // German
    { langcode: "uk", translation: "Українська" }, // Ukrainian
    { langcode: "pt", translation: "Português" }, // Portuguese
    { langcode: "ar", translation: "العربية" }, // Arabic
  ],
};

const mapping = {};
Object.keys(languages).forEach((lang) => {
  languages[lang].forEach((langObj) => {
    mapping[langObj.langcode] = langObj.translation;
  });
});
mapping["en"] = "English";

export const langcode2language = mapping;