import { backendNode, backendPy } from "System/system";
import store from "System/reduxStore";
import { languages } from "Data/languages";


// -----------------------------------------------------
// -----------------------------------------------------
const removeArticle = (word) =>
  word.lemma.replace(/\b(?:der|die|das)\b/g, "").trim();

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// -----------------------------------------------------
// -----------------------------------------------------
export const findVocab = (tokens, text, cb) => async (dispatch) => {
  dispatch({ type: "SET_VOCAB", vocab: [] });

  const register = {};

  for (const parindex in tokens) {
    for (const sentindex in tokens[parindex]) {
      for (const tokenindex in tokens[parindex][sentindex]) {
        const token = JSON.parse(
          JSON.stringify(tokens[parindex][sentindex][tokenindex])
        );
        delete token.foils;
        register[token.token] = token;
      }
    }
  }

  let vocab = [];
  let res = await backendPy("vocab", "find", {
    text,
  });
  if (res.data) {
    const { result } = res.data;
    for (const token of result) {
      if (register[token]) {
        if (register[token].pos !== "proper noun") {
          vocab.push(register[token]);
        }
      }
    }
    dispatch({ type: "SET_VOCAB", vocab });
    vocab = JSON.parse(JSON.stringify(vocab));

    for (const token of vocab) {
      await dispatch(addVocab(token));
      sleep(1000);
    }
  }
  cb();
};

// -----------------------------------------------------
// -----------------------------------------------------
export const addVocab = (vocab) => async (dispatch) => {
  dispatch({ type: "ADD_VOCAB", token: vocab });
  const token = JSON.parse(JSON.stringify(vocab));
  const foils = {};

  const { teachingLanguage } = store.getState().user;
  const foreignLanguages = languages[teachingLanguage].map((l) => l.langcode);
  const languageCodes = [teachingLanguage, "en", ...foreignLanguages];

  for (const language of languageCodes) {
    foils[language] = [];
    const res = await backendNode("foils", "get", {
      tokenString: JSON.stringify(token),
      language,
    });
    if (res.data) {

      let allFoils = res.data.results
        .map((x) => x.item)
        .filter((x) => x !== "" && x)
    
      console.log("All foils", language, allFoils);
      const fiveFoils = allFoils
        .slice(0, 5)
        .map((x) => x.replace(/["`‘’“”]/g, ""));
      console.log("Five foils", language, fiveFoils);

      foils[language] = fiveFoils;
    }
  }
  console.log("Foils", foils);
  console.log("Token", token);
  dispatch({
    type: "SET_SINGLE_SET_OF_FOILS",
    token,
    foils,
  });
};

// -----------------------------------------------------
// -----------------------------------------------------
export const addVocab__ = (vocab) => async (dispatch) => {
  dispatch({ type: "ADD_VOCAB", token: vocab });
  const token = JSON.parse(JSON.stringify(vocab));
  const word = removeArticle(token);
  const res = await backendPy("vocab", "foils_single", { input: word });
  if (res.data) {
    const result = JSON.parse(res.data.result);
    if (result.foils) {
      const foils = result.foils.map(capitalize);
      dispatch({
        type: "SET_SINGLE_SET_OF_FOILS",
        token,
        foils,
        word: capitalize(word),
      });
    }
  }
};

// -----------------------------------------------------
// -----------------------------------------------------
export const generateQuestions = (text, cb) => async (dispatch) => {
  const capitalize = (x) => x.charAt(0).toUpperCase() + x.slice(1);

  const res = await backendPy("questions", "generate", {
    text,
  });
  if (res.data) {
    const { result } = res.data;
    console.log("Result is", result);
    const questions = result.map((q) => ({
      ...q,
      correct: capitalize(q.correct),
      incorrect: q.incorrect.map(capitalize),
    }));
    dispatch({ type: "SET_QUESTIONS", questions });
  }
  cb();
};

// -----------------------------------------------------
// -----------------------------------------------------
export const addQuestion = (text, questions, cb) => async (dispatch) => {
  const capitalize = (x) => x.charAt(0).toUpperCase() + x.slice(1);

  const res = await backendPy("questions", "add", {
    text, questions: JSON.stringify(questions)
  });
  if (res.data) {
    const { result } = res.data;
    if(result) {
      result.correct = capitalize(result.correct);
      result.incorrect = result.incorrect.map(capitalize);
      dispatch({ type: "ADD_QUESTION", question: result });
    }
  }
  cb();
};

// -----------------------------------------------------
// -----------------------------------------------------
export const eraseQuestion = (index) => async (dispatch) => {
  dispatch({ type: "ERASE_QUESTION", index });
};

// -----------------------------------------------------
// -----------------------------------------------------
export const editQuestion = (index, question) => async (dispatch) => {
  dispatch({ type: "EDIT_QUESTION", index, question });
};

// -----------------------------------------------------
// -----------------------------------------------------
export const editCorrect = (index, answer) => async (dispatch) => {
  dispatch({ type: "EDIT_CORRECT", index, answer });
};

// -----------------------------------------------------
// -----------------------------------------------------
export const editIncorrect =
  (index, answerindex, answer) => async (dispatch) => {
    dispatch({ type: "EDIT_INCORRECT", index, answerindex, answer });
  };
