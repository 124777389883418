import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// ------------------------------------------------
import { newProject } from "Redux/projects/actions";


// ------------------------------------------------
// ------------------------------------------------
const NewProject = ({ onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const [generating, setGenerating] = useState(false);
  
    const setUpNew = () => {
      setGenerating(true);
      dispatch(
        newProject(value, navigate, () => {
          setGenerating(false);
          onClose();
        })
      );
    };
  
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">New Article</p>
            <button className="delete" aria-label="close" onClick={onClose} />
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Title</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Article Title"
                  onChange={(evt) => setValue(evt.target.value)}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="buttons is-small">
              <button
                className={`button is-small is-primary ${
                  generating ? "is-loading" : ""
                }`}
                disabled={!value}
                onClick={setUpNew}
              >
                New Article
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  };

  export default NewProject;
  